import React, { useState } from "react";
import Sidebar from "../components/SideBar";
import "../style/App.css";
import { getUrl } from "../components/apiUrl";
import { useAuth } from "../contexts/AuthContext";
import logoutIcon from "../img/logout.svg";
import { useNavigate } from "react-router-dom";

function Profile() {
  const { user, updateUser, logout } = useAuth();
  const [firstName, setFirstName] = useState(user?.name || "");
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    if (!isEditing) {
      setIsEditing(true);
      return;
    }

    setIsLoading(true);
    try {
      const apiUrl = getUrl();
      const response = await fetch(`${apiUrl}/api/user/profile`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ name: firstName }),
      });

      if (response.ok) {
        const updatedUserData = await response.json();
        updateUser(updatedUserData.user);
        setIsEditing(false);
        alert("Данные профиля обновлены");
      } else {
        console.error("Ошибка обновления данных профиля");
      }
    } catch (error) {
      console.error("Ошибка при обновлении данных профиля:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSidebarLogout = () => {
    logout();
    navigate("/login");
  };

  const getInitials = () => {
    return firstName ? firstName.charAt(0).toUpperCase() : "И";
  };

  return (
    <div className="container">
      <Sidebar />
      <div className="profile-content">
        <div className="profile-info">
          <h1 className="profile-h1">Информация о пользователе</h1>
          {isLoading ? (
            <p>Загрузка данных...</p>
          ) : (
            <form className="profile-form" onSubmit={handleUpdateProfile}>
              <div className="profile-avatar">
                <span className="avatar-initials">{getInitials()}</span>
              </div>
              <div className="profile-details">
                <div className="form-group">
                  <label htmlFor="firstName">Имя</label>
                  <div className="name-edit-container">
                    {isEditing ? (
                      <input
                        type="text"
                        id="firstName"
                        placeholder="Введите имя"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    ) : (
                      <span className="profile-name">{firstName}</span>
                    )}
                    <button type="submit" className="update-button">
                      {isEditing ? "Сохранить изменения" : "Обновить профиль"}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}

          <div className="profile-subscription">
            <div className="subscription-card">
              <div className="unsubscription-header">
                <p className="color-highlight">Куплено 10 расчетов</p>
              </div>
              <div className="tarif-wrapper">
                <span className="tarif">Доступно: 10 расчетов</span>
                <div className="price">
                  <span className="total tarif">Стоимость:</span>
                  <span className="tarif"> 500 ₽/10 расчетов</span>
                </div>
              </div>
              {/* <strong>
                <span className="total">Дата следующего платежа: </span>
                <span>08.11.2024</span>
              </strong> */}
            </div>
          </div>
        </div>

        <button
          className="logout-button sidebar-logout"
          onClick={handleSidebarLogout}
        >
          <img src={logoutIcon} alt="logOut" />
          Выйти из аккаунта
        </button>
      </div>
    </div>
  );
}

export default Profile;
