import React, { useState, useEffect } from "react";
import "../style/Landing.css";
import eyeIcon from "../img/eye-open.svg";
import eyeClosedIcon from "../img/eye-closed.svg";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import LandingBcc from "../img/Browser2.png";
import starIcon from "../img/star.svg";
import mobileImg from "../img/Content.png";
import calculatorImage from "../img/Browser-mobile.png";


function LandingMainContent() {
  const { login } = useAuth();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    try {
      const response = await fetch(
        "https://dp-calculating-api-8d4e954ecc53.herokuapp.com/api/auth/register",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        const responseData = await response.json();
        setError(responseData.msg || "Ошибка регистрации");
      } else {
        const responseData = await response.json();
        login(responseData.user, responseData.token);
        navigate("/new-calculation");
      }
    } catch (err) {
      setError("Ошибка сети");
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="landing-content">
      <div className="landing-top">
        <section className="landing-calculator-section">
          <img
            src={LandingBcc}
            alt="Фон калькулятора"
            className="calculator-background-image"
          />
          <div className="landing-container">
            <div className="landing-card">
              <h2>Калькулятор</h2>
              <p className="features-description">
                Команда опытных мастеров вывела самые точные формулы расчета
                материалов и комбинации подбора материалов под определенные
                ситуации. Мы работали для вас!
              </p>
              <button
                onClick={() => {
                  navigate("/login");
                }}
                className="landing-start-calculation"
              >
                Начать пользоваться
              </button>
            </div>
          </div>
        </section>
      </div>

      <div className="small-gap"></div>

      <section className="landing-calculator-section mobile-calculator">
        <div className="landing-container">
          <div className="mobile-calculator-card">
            <h2 className="mobile-calculator-title">Калькулятор</h2>
            <p className="mobile-calculator-description">
              Команда опытных мастеров вывела самые точные формулы расчета
              материалов и комбинации подбора материалов под определенные
              ситуации. Мы работали для вас!
            </p>
            <button  onClick={() => {
                  navigate("/login");
                }} className="mobile-calculator-button">
              Начать пользоваться
            </button>
            <img
              src={calculatorImage}
              alt="Калькулятор Пример"
              className="mobile-calculator-image"
            />
          </div>
        </div>
      </section>

      <div className="small-gap"></div>

      <section id="features" className="landing-features-section">
        <div className="landing-container">
          <h3>Преимущества</h3>
          <p className="features-description">
            Мы постоянно совершенствуем и развиваем платформу, уже сейчас мы
            выгодно отличаемся от конкурентов
          </p>
          <div className="landing-features">
            <div className="landing-feature-card">
              <img src={starIcon} alt="Star Icon" className="feature-icon" />
              <h4>Разные производители</h4>
              <p>Выбирайте материалы, с которыми работаете</p>
            </div>
            <div className="landing-feature-card">
              <img src={starIcon} alt="Star Icon" className="feature-icon" />
              <h4>Работы</h4>
              <p>Включайте в смету работы - по своей цене!</p>
            </div>
            <div className="landing-feature-card">
              <img src={starIcon} alt="Star Icon" className="feature-icon" />
              <h4>Возможность повлиять на развитие приложения</h4>
              <p>Оставляйте пожелания - мы их обязательно реализуем!</p>
            </div>
            <div className="landing-feature-card">
              <img src={starIcon} alt="Star Icon" className="feature-icon" />
              <h4>Своя цена</h4>
              <p>Платите только за расчеты, без скрытых платежей</p>
            </div>
          </div>
        </div>
      </section>

      <div className="small-gap"></div>

      <section className="sub">
        <div className="landing-subscription">
          <div className="landing-container">
            <div className="sub-content">
              <h3>Присоединяйтесь к нам</h3>
              <form onSubmit={handleSubmit} className="landing-newsletter-form">
                <div className="sub-top-wrapper">
                  <div className="sub-input-half">
                    <input
                      type="text"
                      name="name"
                      placeholder="Ваше имя"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="sub-input-half">
                    <input
                      type="email"
                      name="email"
                      placeholder="Введите e-mail"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>

                <div className="sub-input password-field">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Введите пароль"
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />
                  <img
                    src={showPassword ? eyeClosedIcon : eyeIcon}
                    alt="Показать пароль"
                    className="eye-icon"
                    onClick={togglePasswordVisibility}
                  />
                </div>
              </form>
              {error && <p className="error-message">{error}</p>}
              <button
                type="submit"
                className="landing-reg-button"
                disabled={loading}
              >
                {loading ? "Регистрация..." : "Зарегистрироваться"}
              </button>
              <p className="consent-text">
                Нажимая кнопку "Зарегистрироваться" Вы соглашаетесь на обработку
                персональных данных.
              </p>
            </div>
          </div>
        </div>
        <div className="big-gap"></div>
      </section>

      <section className="sub-mobile">
        <div className="small-gap"></div>
        <div className="landing-container">
          <div className="sub-content-mobile">
            <h3>Присоединяйтесь к нам</h3>
            <form onSubmit={handleSubmit} className="landing-newsletter-form">
              <div className="sub-top-wrapper">
                <div className="sub-input-half">
                  <input
                    type="text"
                    name="name"
                    placeholder="Ваше имя"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="sub-input-half">
                  <input
                    type="email"
                    name="email"
                    placeholder="Введите e-mail"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div className="sub-input password-field">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Введите пароль"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
                <img
                  src={showPassword ? eyeClosedIcon : eyeIcon}
                  alt="Показать пароль"
                  className="eye-icon"
                  onClick={togglePasswordVisibility}
                />
              </div>
            </form>
            <div className="sub-mobile-img">
              <img src={mobileImg} alt="Star Icon" className="mobile-img" />
            </div>
            {error && <p className="error-message">{error}</p>}
            <button
              type="submit"
              className="landing-reg-button"
              disabled={loading}
            >
              {loading ? "Регистрация..." : "Зарегистрироваться"}
            </button>
            <p className="consent-text">
              Нажимая кнопку "Зарегистрироваться" Вы соглашаетесь на обработку
              персональных данных.
            </p>
          </div>
        </div>
      </section>
      <div className="big-gap-mobile"></div>
    </div>
  );
}

export default LandingMainContent;
