import React, { useState } from "react";
import "../style/App.css";
import eyeIcon from "../img/eye-open.svg";
import eyeClosedIcon from "../img/eye-closed.svg";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import FrameFront from "../img/phones2.png";
import logo from "../img/Logo-circle.svg";
import { useAuth } from "../contexts/AuthContext";
import LoadingScreen from "../components/LoadingScreen";

function Register() {
  const { login } = useAuth();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    promoCodeInput: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [hasPromoCode, setHasPromoCode] = useState(false); // Добавляем состояние для чекбокса
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);
    setProgress(0);

    try {
      const response = await fetch(
        "https://dp-calculating-api-8d4e954ecc53.herokuapp.com/api/auth/register",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        const responseData = await response.json();
        setError(responseData.msg || "Ошибка регистрации");
        setLoading(false);
      } else {
        const responseData = await response.json();
        login(responseData.user, responseData.token);
        setProgress(100);
        navigate("/new-calculation");
      }
    } catch (err) {
      setError("Ошибка сети", err);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="page-container">
      {loading ? (
        <LoadingScreen progress={progress} setProgress={setProgress} />
      ) : (
        <>
          <div className="left-column">
            <motion.div
              className="login-register-container"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <img src={logo} className="header-logo" alt="logo" />
              <h2>Начните работу</h2>
              <p className="subtitle">
                Создайте свою бесплатную учетную запись
              </p>
              <form onSubmit={handleSubmit}>
                <div className="input-group">
                  <div className="input-field">
                    <input
                      type="text"
                      name="name"
                      placeholder="Ваше имя"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="input-field">
                    <input
                      type="email"
                      name="email"
                      placeholder="Введите e-mail"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                {/* <div className="input-check">
                  <input
                    className="input-check-checkbox"
                    type="checkbox"
                    checked={hasPromoCode}
                    onChange={() => setHasPromoCode(!hasPromoCode)}
                  />
                  <span className="checkbox-span">У меня есть промокод</span>
                </div> */}
                {/* {hasPromoCode && ( */}
                  {/* <div className="input-field">
                    <input
                      type="text"
                      name="promoCodeInput"
                      placeholder="Введите промокод"
                      value={formData.promoCodeInput}
                      onChange={handleChange}
                    />
                  </div> */}
                <div className="input-field password-field">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Введите пароль"
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />
                  <img
                    src={showPassword ? eyeClosedIcon : eyeIcon}
                    alt="Показать пароль"
                    className="eye-icon"
                    onClick={togglePasswordVisibility}
                  />
                </div>

                {error && <p className="error-message">{error}</p>}



                <button
                  type="submit"
                  className="primary-button register-btn glow"
                  disabled={loading}
                >
                  {loading ? "Регистрация..." : "Зарегистрироваться"}
                </button>
                <p className="link">
                  У вас уже есть аккаунт?
                  <br />
                  <a onClick={() => navigate("/login")} className="auth-link">
                    <span className="link-red">Авторизоваться</span>
                  </a>
                </p>
              </form>
            </motion.div>
          </div>
          <div className="right-column right-register">
            <img src={FrameFront} alt="Front Image" className="foreground-image-phone" />
          </div>
        </>
      )}
    </div>
  );
}

export default Register;
