import React from "react";
import SuppliesItem from "../components/SuppliesItem";
import "../style/App.css";
import { useMaterials } from "../contexts/MaterialsContext";

function ResultsGrouped() {
  const {
    groupedMaterials
  } = useMaterials();

  const totalQuantity = groupedMaterials.reduce((acc, item) => acc + (Number(item.units) || 0), 0)

  const totalSum = groupedMaterials.reduce(
    (acc, item) => acc + (Number(item.totalPrice) || 0),
    0
  );

  const totalJobSum = groupedMaterials.reduce(
    (acc, item) => acc + (Number(item.jobPrice) || 0),
    0
  );

  return (
    <div className="results-block">
      <h2 className="results-block-title">
        Результат
      </h2>
      <div className="supplies-list">
        {groupedMaterials.map((item, index) =>
            <SuppliesItem
              key={index}
              id={item.id}
              name={item.name}
              packaging={item.packaging}
              consumption={item.consumptionPerSquareMeter}
              quantity={item.quantity || item.value}
              icon={item.icon}
              unit={item.unit}
              units={Number(item.units)}
              price={Number(item.price)}
              totalPrice={Number(item.totalPrice)}
              item={item}
              isGrouped={true}
              localId={item.localId}
              totalJobPrice={item.jobPrice}
            />
        )}

        <div className="supplies-total">
          <span className="total-label">Итого</span>
          <div className="total-values">
            {(
              <div className="total-detail">
                <span className="total-name">Кол-во</span>
                <span className="total-quantity">
                  {totalQuantity.toFixed(2)} ед.
                </span>
              </div>
            )}
            <div className="total-detail">
              <span className="total-name">Сумма за материалы</span>
              <span className="total-quantity">
                {totalSum.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ₽
              </span>
            </div>
            <div className="total-detail">
              <span className="total-name">Сумма за работу</span>
              <span className="total-quantity">
                {totalJobSum.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ₽
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResultsGrouped;
