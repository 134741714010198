import React, { useState } from "react";
import "../style/App.css";
import more from "../img/more.svg";

import AnalogModal from "../components/AnalogModal";
import { useMaterials } from "../contexts/MaterialsContext";

function SuppliesItem({
  localId,
  name,
  packaging,
  consumption,
  quantity,
  units,
  unit,
  icon,
  isPlasterboard,
  price,
  totalPrice,
  totalJobPrice,
  id,
  item,
  isGrouped = false,
}) {
  const { wallArea, changeJobPrice } = useMaterials();
  const [isAnalogModalOpen, setIsAnalogModalOpen] = useState(false);
  const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 });

  const handleMoreClick = (event) => {
    const scrollOffsetY = window.scrollY || document.documentElement.scrollTop;
    const scrollOffsetX = window.scrollX || document.documentElement.scrollLeft;

    setClickPosition({
      x: event.clientX + scrollOffsetX,
      y: event.clientY + scrollOffsetY,
    });
    setIsAnalogModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsAnalogModalOpen(false);
  };

  const handleJobPriceChange = (event) => {
    const value = parseFloat(event.target.value);
    const totalJobPrice = value * wallArea;
    changeJobPrice(localId, totalJobPrice)
  };

  return (
    <div className="supplies-item">
      <div className="supplies-header">
        <div className="supplies-icon">
          <img src={icon} alt={name} className="supplies-icon-img" />
        </div>
        <div className="supplies-detail-first">
          <span className="detail-item-name">{name}</span>
          <span className="detail-value-light">
            {" "}
            {packaging} {unit}
          </span>
        </div>
      </div>

      <div className="supplies-details">
        <div className="supplies-description">
          {!isGrouped && (
            <div className="supplies-detail">
              <span className="detail-title">Расход на м2:</span>
              <span className="detail-value">
                {consumption} {unit}
              </span>
            </div>
          )}

          <div className="supplies-detail">
            <span className="detail-title">Цена за шт:</span>
            <span className="detail-value">
              {price.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              ₽
            </span>
          </div>

          <div className="supplies-detail supplies-quant">
            <span className="detail-title">Кол-во:</span>
            <span className="detail-value quantity-value">{units} упк.</span>
            <span className="detail-value quantity-value-mobile">
              {units} упк.
            </span>
          </div>

          <div className="supplies-detail supplies-detail-last">
            <span className="detail-title">Сумма:</span>
            <span className="detail-value">
              {totalPrice.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              ₽
            </span>
          </div>
          <button className="more-btn" onClick={handleMoreClick}>
            <img src={more} alt="more-icon" />
          </button>
        </div>

        {!isGrouped && (
          <div className="job-pricing">
            <div className="supplies-detail supplies-detail-last">
              <span className="detail-title">
                Введите цену за работу материалом ₽ / м2
              </span>
              <input
                type="number"
                className="price-input" 
                placeholder="Введите цену"
                min={0}
                onChange={handleJobPriceChange}
              />
            </div>
          </div>
        )}
         {isGrouped && (
          <div className="job-pricing">
            <div className="supplies-detail supplies-detail-last">
              <span className="detail-title">Цена за работу:</span>
              <span className="detail-value">
                {totalJobPrice?.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
                ₽
              </span>
            </div>
          </div>
        )}
      </div>

      <AnalogModal
        item={item}
        isOpen={isAnalogModalOpen}
        onClose={handleCloseModal}
        clickPosition={clickPosition}
      />
    </div>
  );
}

export default SuppliesItem;
