import React, { useEffect } from "react";
import squareMeterIcon from "../img/m2.svg";
import linearMeterIcon from "../img/pm.svg";
import { useMaterials } from "../contexts/MaterialsContext";

function CalculatorStep({
  wallArea,
  setWallArea: setDefaultArea,
  selectedOption, // Выбранная опция передается из родительского компонента
  setSelectedOption, // Функция для обновления выбранной опции
}) {
  const {
    setSelectedWallSurface,
    setWallArea,
  } = useMaterials();

  // Обрабатываем изменение выбора радио-кнопки
  const handleOptionChange = (e) => {
    setSelectedWallSurface(e.target.value);
    setSelectedOption(e.target.value); // Обновляем состояние с выбранной опцией
  };

  useEffect(() => {
    setWallArea(wallArea)
  }, [])

  return (
    <div className="calculator-container">
      <div className="calculator-page">
        <div className="calculator-card" style={{ position: "relative" }}>
          <div className="calculator-description">
            <h1>Размеры</h1>
            <p>
            Быстрый расчет количества необходимых материалов для малярных работ
            </p>
          </div>
          <div className="calculator-input-group">
            <div className="calculator-input-field">
              <label>Площадь поверхности</label>
              <div className="input-wrapper">
                <input
                  type="number"
                  value={wallArea === 0 ? "" : wallArea}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (!isNaN(Number(value))) {
                      setDefaultArea(Number(value));
                      setWallArea(Number(value));
                    }
                  }}
                  placeholder="Площадь поверхности"
                />
                <img src={squareMeterIcon} alt="м2" className="unit-icon" />
              </div>
              <div className="radio-btn-wrapper">
                <p className="radio-btn-p">Основание поверхности стен: </p>
                <div className="radio-buttons">
                  <input
                    type="radio"
                    id="plaster"
                    name="option"
                    value="plaster"
                    checked={selectedOption === "plaster"}
                    onChange={handleOptionChange}
                  />
                  <label htmlFor="plaster">Штукатурка</label>

                  <input
                    type="radio"
                    id="drywall"
                    name="option"
                    value="drywall"
                    checked={selectedOption === "drywall"}
                    onChange={handleOptionChange}
                  />
                  <label htmlFor="drywall">Гипсокартон</label>
                </div>
              </div>
            </div>
            <div className="calculator-input-field"></div>
            {/* <div className="calculator-input-field">
              <label>Расчет ленты</label>
              <div className="input-wrapper">
                <input
                  type="number"
                  value={tapeLength}
                  onChange={(e) => setTapeLength(Number(e.target.value))}
                  placeholder="Лента"
                />
                <img src={linearMeterIcon} alt="п.м" className="unit-icon" />
              </div>
            </div> */}

            {/* <div className="calculator-input-field">
              <label>Площадь потолка</label>
              <div className="input-wrapper">
                <input
                  type="number"
                  value={plasterboardArea}
                  onChange={(e) => setPlasterboardArea(Number(e.target.value))}
                  placeholder="Площадь потолка"
                />
                <img src={squareMeterIcon} alt="м2" className="unit-icon" />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CalculatorStep;
