import React from "react";
import "../style/Landing.css";

function TermsandConditions({ title, content }) {
  return (
    <div className="agreement-container">
      <h2>{title}</h2>
      {content.map((section, index) => (
        <section className="agreement-section" key={index}>
          <h3 className="agreement-h3">{section.header}</h3>
          <p className="agreement-header-text">{section.text}</p>
          {section.subsections.map((subsection, subIndex) => (
            <div className="agreement-card" key={subIndex}>
              <h4>{subsection.subhead}</h4>
              <p>{subsection.text}</p>
            </div>
          ))}
        </section>
      ))}
    </div>
  );
}


export default TermsandConditions;
