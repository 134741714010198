import React from "react";
import SuppliesItem from "../components/SuppliesItem";
import WorkItem from "../components/WorkItems";
import "../style/App.css";

function Results({ title, items, count }) {
  const isMaterials = title === "Материалы";

  const totalQuantity = isMaterials
    ? items.reduce((acc, item) => acc + (Number(item.units) || 0), 0)
    : null;

  const totalSum = items.reduce(
    (acc, item) => acc + (Number(item.totalPrice) || 0),
    0
  );

  return (
    <div className="results-block">
      <h2 className="results-block-title">
        {title} <span className="results-block-count">{count} позиций</span>
      </h2>
      <div className="supplies-list">
        {items.map((item, index) =>
          isMaterials ? (
            <SuppliesItem
              key={index}
              id={item.id}
              name={item.name}
              packaging={item.packaging}
              consumption={item.consumptionPerSquareMeter}
              quantity={item.quantity || item.value}
              icon={item.icon}
              unit={item.unit}
              units={Number(item.units)}
              price={Number(item.price)}
              totalPrice={Number(item.totalPrice)}
              item={item}
              localId={item.localId}
              totalJobPrice={item.jobPrice}
            />
          ) : (
            <WorkItem
              key={`${item._id}-${index}`}
              id={item.id}
              name={item.name}
              consumption={item.consumptionPerSquareMeter}
              totalPrice={Number(item.totalPrice)}
            />
          )
        )}

        {count > 0 && (
          <div className="supplies-total">
            <span className="total-label">Итого</span>
            <div className="total-values">
              {isMaterials && (
                <div className="total-detail">
                  <span className="total-name">Кол-во</span>
                  <span className="total-quantity">
                    {totalQuantity.toFixed(2)} ед.
                  </span>
                </div>
              )}
              <div className="total-detail">
                <span className="total-name">Сумма</span>
                <span className="total-quantity">
                  {totalSum.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ₽
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Results;
