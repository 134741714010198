import React from "react";
import "../style/App.css";

function UnsubscribeModal({ isOpen, onClose }) {
  if (!isOpen) return null;

  return (
    <div className="unsubscribe-modal-overlay">
      <div className="unsubscribe-modal-content">
        <button className="unsubscribe-modal-close" onClick={onClose}>
          ✖
        </button>
        <h2>Почему вы хотите уйти?</h2>
        <form>
          <div className="unsubscribe-modal-option">
            <input className="unsubscribe-input" type="radio" id="option1" name="reason" value="expensive" />
            <label htmlFor="option1">Дорого</label>
          </div>
          <div className="unsubscribe-modal-option">
            <input className="unsubscribe-input" type="radio" id="option2" name="reason" value="expectations" />
            <label htmlFor="option2">Это не то, что я ожидал(а) от платформы</label>
          </div>
          <div className="unsubscribe-modal-option">
            <input className="unsubscribe-input" type="radio" id="option3" name="reason" value="no-need" />
            <label htmlFor="option3">Подписка больше не нужна</label>
          </div>
          <div className="unsubscribe-modal-option">
            <input className="unsubscribe-input" type="radio" id="option4" name="reason" value="other" />
            <label htmlFor="option4">Другое</label>
          </div>
        </form>
        <button className="unsubscribe-stay-button" onClick={onClose}>Убедили, остаюсь</button>
        <button className="unsubscribe-cancel-button" >
          Отменить подписку
        </button>
      </div>
    </div>
  );
}

export default UnsubscribeModal;
