import React, { useState, useEffect } from "react";
import { getUrl } from "../components/apiUrl";

function AdminProductsPage() {
  const [materials, setMaterials] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [materialIdToEdit, setMaterialIdToEdit] = useState(null);
  const [newMaterial, setNewMaterial] = useState({
    manufacturer: "",
    name: "",
    packaging: "",
    unit: "",
    icon: "",
    stages: [],
  });
  const [error, setError] = useState("");
  const [newStage, setNewStage] = useState({
    stage: "",
    consumptionPerSquareMeter: "",
    formula: "",
    wallType: "",
    jointType: "",
    analogs: [],
  });

  const apiUrl = getUrl();

  useEffect(() => {
    fetchMaterials();
  }, []);

  const fetchMaterials = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/materials`);
      if (!response.ok) throw new Error("Ошибка при загрузке материалов");

      const data = await response.json();
      const activeMaterials = data.filter((material) => !material.isDeleted);
      setMaterials(activeMaterials);
    } catch (err) {
      console.error("Ошибка при загрузке материалов:", err);
      setError("Не удалось загрузить материалы");
    }
  };

  const handleSubmit = async () => {
    try {
      const materialToSubmit = prepareAnalogsForServer();
      const endpoint = isEditing
        ? `${apiUrl}/api/materials/${materialIdToEdit}`
        : `${apiUrl}/api/materials/create-many`;
      const method = isEditing ? "PATCH" : "POST";

      const response = await fetch(endpoint, {
        method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(isEditing ? materialToSubmit : [materialToSubmit]),
      });
      if (!response.ok)
        throw new Error("Ошибка при добавлении/обновлении материала");

      setIsEditing(false);
      setMaterialIdToEdit(null);
      setNewMaterial({
        manufacturer: "",
        name: "",
        packaging: "",
        unit: "",
        icon: "",
        stages: [],
      });
      fetchMaterials();
    } catch (err) {
      console.error("Ошибка при отправке данных:", err);
      setError("Не удалось добавить материалы");
    }
  };

  const addStage = () => {
    setNewMaterial((prev) => ({
      ...prev,
      stages: [...prev.stages, { ...newStage }],
    }));
    setNewStage({
      stage: "",
      consumptionPerSquareMeter: "",
      formula: "",
      wallType: "",
      jointType: "",
      analogs: [],
    });
  };

  const addAnalog = (index) => {
    const updatedStages = [...newMaterial.stages];
    if (!updatedStages[index].analogs) {
      updatedStages[index].analogs = [];
    }
    updatedStages[index].analogs = [...updatedStages[index].analogs, ""];
    setNewMaterial({ ...newMaterial, stages: updatedStages });
  };

  const prepareAnalogsForServer = () => {
    const preparedStages = newMaterial.stages.map((stage) => ({
      ...stage,
      analogs: stage.analogs
        .map((analog) =>
          typeof analog === "object" && analog._id ? analog._id : analog
        )
        .filter((analog) => typeof analog === "string" && analog.trim() !== ""),
    }));
    return { ...newMaterial, stages: preparedStages };
  };

  const removeAnalog = (stageIndex, analogIndex) => {
    const updatedStages = [...newMaterial.stages];
    updatedStages[stageIndex].analogs.splice(analogIndex, 1);
    setNewMaterial({ ...newMaterial, stages: updatedStages });
  };

  const handleAnalogChange = (stageIndex, analogIndex, value) => {
    const updatedStages = [...newMaterial.stages];
    updatedStages[stageIndex].analogs[analogIndex] = value;
    setNewMaterial({ ...newMaterial, stages: updatedStages });
  };

  const deleteMaterial = async (materialId) => {
    try {
      const response = await fetch(`${apiUrl}/api/materials/${materialId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) throw new Error("Ошибка при удалении материала");

      setMaterials((prevMaterials) =>
        prevMaterials.filter((material) => material._id !== materialId)
      );
    } catch (err) {
      console.error("Ошибка при удалении материала:", err);
      setError("Не удалось удалить материал");
    }
  };

  const startEditing = (material) => {
    setIsEditing(true);
    setMaterialIdToEdit(material._id);

    const { _id, id, ...editMaterial } = material;
    setNewMaterial({ ...editMaterial });
  };

  return (
    <div
      style={{
        padding: "20px",
        overflow: "hidden",
      }}
    >
      <h1>Админская панель - Материалы</h1>

      <div
        style={{
          display: "flex",
          paddingTop: "20px",
          flexDirection: "row-reverse",
          gap: "20px",
        }}
      >
        <div
          style={{
            flex: 1,
            border: "1px solid #e0e0e0",
            padding: "30px",
            borderRadius: "12px",
            background: "#ffffff",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.05)",
            maxWidth: "800px",
            margin: "0 auto",
          }}
        >
          {error && (
            <p style={{ color: "red", marginBottom: "20px" }}>{error}</p>
          )}

          <h2 style={{ color: "#333", marginBottom: "30px" }}>
            {isEditing ? "Редактировать материал" : "Добавить новый материал"}
          </h2>
          <div>
            <div
              style={{
                marginBottom: "20px",
                display: "flex",
                flexWrap: "wrap",
                gap: "20px",
              }}
            >
              <div style={{ flex: "1 1 200px" }}>
                <label
                  style={{
                    display: "block",
                    marginBottom: "5px",
                    color: "#555",
                  }}
                >
                  Производитель
                </label>
                <select
                  value={newMaterial.manufacturer}
                  onChange={(e) =>
                    setNewMaterial({
                      ...newMaterial,
                      manufacturer: e.target.value,
                    })
                  }
                  style={{
                    width: "100%",
                    padding: "10px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                  }}
                >
                  <option value="" disabled>
                    Выберите производителя
                  </option>
                  <option value="knauf">Кнауф</option>
                  <option value="smit">Смит</option>
                </select>
              </div>

              <div style={{ flex: "1 1 200px", marginRight: "20px" }}>
                <label
                  style={{
                    display: "block",
                    marginBottom: "5px",
                    color: "#555",
                  }}
                >
                  Название
                </label>
                <input
                  type="text"
                  placeholder="Введите название"
                  value={newMaterial.name}
                  onChange={(e) =>
                    setNewMaterial({ ...newMaterial, name: e.target.value })
                  }
                  style={{
                    width: "100%",
                    padding: "10px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                  }}
                />
              </div>

              <div style={{ flex: "1 1 200px", marginRight: "20px" }}>
                <label
                  style={{
                    display: "block",
                    marginBottom: "5px",
                    color: "#555",
                  }}
                >
                  Упаковка
                </label>
                <input
                  id="package"
                  type="number"
                  placeholder="Введите объем упаковки"
                  value={newMaterial.packaging}
                  onChange={(e) =>
                    setNewMaterial({
                      ...newMaterial,
                      packaging: +e.target.value,
                    })
                  }
                  style={{
                    width: "100%",
                    padding: "10px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                  }}
                />
              </div>

              <div style={{ flex: "1 1 200px", marginRight: "20px" }}>
                <label
                  style={{
                    display: "block",
                    marginBottom: "5px",
                    color: "#555",
                  }}
                >
                  Единица измерения
                </label>
                <select
                  value={newMaterial.unit}
                  onChange={(e) =>
                    setNewMaterial({ ...newMaterial, unit: e.target.value })
                  }
                  style={{
                    width: "100%",
                    padding: "10px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                  }}
                >
                  <option value="" disabled>
                    Выберите единицу измерения
                  </option>
                  <option value="кг">кг</option>
                  <option value="л">л</option>
                  <option value="м²">м²</option>
                </select>
              </div>

              <div style={{ flex: "1 1 200px", marginBottom: "20px" }}>
                <label
                  style={{
                    display: "block",
                    marginBottom: "5px",
                    color: "#555",
                  }}
                >
                  Иконка (URL)
                </label>
                <input
                  type="text"
                  placeholder="Введите URL иконки"
                  value={newMaterial.icon}
                  onChange={(e) =>
                    setNewMaterial({ ...newMaterial, icon: e.target.value })
                  }
                  style={{
                    width: "98%",
                    padding: "10px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                  }}
                />
              </div>
            </div>

            <h4
              style={{ marginTop: "40px", marginBottom: "20px", color: "#333" }}
            >
              Добавить этап к материалу
            </h4>
            {newMaterial.stages.map((stage, stageIndex) => (
              <div
                key={stageIndex}
                style={{
                  marginBottom: "30px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginBottom: "20px",
                    gap: "20px",
                  }}
                >
                  <div
                    style={{
                      flex: "1 1 200px",
                    }}
                  >
                    <label
                      style={{
                        display: "block",
                        marginBottom: "5px",
                        color: "#555",
                      }}
                    >
                      Этап
                    </label>
                    <select
                      value={stage.stage}
                      onChange={(e) => {
                        const updatedStages = [...newMaterial.stages];
                        updatedStages[stageIndex].stage = e.target.value;
                        setNewMaterial({
                          ...newMaterial,
                          stages: updatedStages,
                        });
                      }}
                      style={{
                        width: "100%",
                        padding: "10px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                      }}
                    >
                      <option value="" disabled>
                        Выберите этап
                      </option>
                      <option value="Q1">Q1</option>
                      <option value="Q2">Q2</option>
                      <option value="Q3">Q3</option>
                      <option value="Q4">Q4</option>
                    </select>
                  </div>

                  <div
                    style={{
                      flex: "1 1 200px",
                      marginRight: "20px",
                    }}
                  >
                    <label
                      style={{
                        display: "block",
                        marginBottom: "5px",
                        color: "#555",
                      }}
                    >
                      Приоритет отображения
                    </label>
                    <input
                      id="order"
                      type="number"
                      placeholder="Введите Приоритет отображения"
                      value={stage.order}
                      onChange={(e) => {
                        const updatedStages = [...newMaterial.stages];
                        updatedStages[stageIndex].order =
                          +e.target.value;
                        setNewMaterial({
                          ...newMaterial,
                          stages: updatedStages,
                        });
                      }}
                      style={{
                        width: "100%",
                        padding: "10px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                      }}
                    />
                  </div>

                  <div
                    style={{
                      flex: "1 1 200px",
                      marginRight: "20px",
                    }}
                  >
                    <label
                      style={{
                        display: "block",
                        marginBottom: "5px",
                        color: "#555",
                      }}
                    >
                      Расход на м²
                    </label>
                    <input
                      id="consumptionPerSquareMeter"
                      type="number"
                      placeholder="Введите расход на м²"
                      value={stage.consumptionPerSquareMeter}
                      onChange={(e) => {
                        const updatedStages = [...newMaterial.stages];
                        updatedStages[stageIndex].consumptionPerSquareMeter =
                          +e.target.value;
                        setNewMaterial({
                          ...newMaterial,
                          stages: updatedStages,
                        });
                      }}
                      style={{
                        width: "100%",
                        padding: "10px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                      }}
                    />
                  </div>

                  <div
                    style={{
                      flex: "1 1 200px",
                      marginRight: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    <label
                      style={{
                        display: "block",
                        marginBottom: "5px",
                        color: "#555",
                      }}
                    >
                      Формула
                    </label>
                    <input
                      id="formula"
                      type="text"
                      placeholder="Введите формулу"
                      value={stage.formula}
                      onChange={(e) => {
                        const updatedStages = [...newMaterial.stages];
                        updatedStages[stageIndex].formula = e.target.value;
                        setNewMaterial({
                          ...newMaterial,
                          stages: updatedStages,
                        });
                      }}
                      style={{
                        width: "100%",
                        padding: "10px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                      }}
                    />
                  </div>

                  <div
                    style={{
                      flex: "1 1 200px",
                      marginRight: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    <label
                      style={{
                        display: "block",
                        marginBottom: "5px",
                        color: "#555",
                      }}
                    >
                      Тип стены
                    </label>
                    <select
                      value={stage.wallType}
                      onChange={(e) => {
                        const updatedStages = [...newMaterial.stages];
                        updatedStages[stageIndex].wallType = e.target.value;
                        setNewMaterial({
                          ...newMaterial,
                          stages: updatedStages,
                        });
                      }}
                      style={{
                        width: "100%",
                        padding: "10px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                      }}
                    >
                      <option value="" disabled>
                        Выберите тип стены
                      </option>
                      <option value="plaster">Штукатурка</option>
                      <option value="drywall">Гипсокартон</option>
                    </select>
                  </div>

                  <div style={{ flex: "1 1 200px", marginBottom: "20px" }}>
                    <label
                      style={{
                        display: "block",
                        marginBottom: "5px",
                        color: "#555",
                      }}
                    >
                      Тип шва
                    </label>
                    <select
                      value={stage.jointType}
                      onChange={(e) => {
                        const updatedStages = [...newMaterial.stages];
                        updatedStages[stageIndex].jointType = e.target.value;
                        setNewMaterial({
                          ...newMaterial,
                          stages: updatedStages,
                        });
                      }}
                      style={{
                        width: "100%",
                        padding: "10px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                      }}
                    >
                      <option value="" disabled>
                        Выберите тип шва
                      </option>
                      <option value="none">Нет</option>
                      <option value="UK">Шов УК</option>
                      <option value="PLUK">Шов Плук</option>
                    </select>
                  </div>
                </div>

                <h5
                  style={{
                    marginTop: "20px",
                    marginBottom: "10px",
                    color: "#333",
                  }}
                >
                  Аналоги
                </h5>
                {stage.analogs.map((analog, analogIndex) => (
                  <div
                    key={analogIndex}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <input
                      type="text"
                      placeholder="Введите ID аналога"
                      value={
                        typeof analog === "object" ? analog._id || "" : analog
                      }
                      onChange={(e) =>
                        handleAnalogChange(
                          stageIndex,
                          analogIndex,
                          e.target.value
                        )
                      }
                      style={{
                        flex: "1",
                        padding: "10px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                        marginRight: "10px",
                      }}
                    />
                    <button
                      onClick={() => removeAnalog(stageIndex, analogIndex)}
                      style={{
                        // backgroundColor: "#dc3545",
                        // color: "#fff",
                        border: "none",
                        padding: "10px 15px",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      Удалить
                    </button>
                  </div>
                ))}
                <button
                  onClick={() => addAnalog(stageIndex)}
                  style={{
                    // backgroundColor: "#007bff",
                    // color: "#fff",
                    border: "none",
                    padding: "10px 20px",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  Добавить аналог
                </button>
              </div>
            ))}

            <div style={{ marginTop: "30px" }}>
              <button
                onClick={addStage}
                style={{
                  // backgroundColor: "#28a745",
                  // color: "#fff",
                  border: "none",
                  padding: "12px 25px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  marginRight: "15px",
                }}
              >
                Добавить этап
              </button>
              <button
                onClick={handleSubmit}
                style={{
                  // backgroundColor: "#007bff",
                  // color: "#fff",
                  border: "none",
                  padding: "12px 25px",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                {isEditing ? "Обновить материал" : "Создать материал"}
              </button>
            </div>
          </div>
        </div>

        <div
          style={{
            flex: 1,
            border: "1px solid #ccc",
            padding: "20px",
            borderRadius: "8px",
            background: "#ffffff",
            overflowY: "auto",
            height: "90vh",
          }}
        >
          <h2 style={{ paddingBottom: "10px" }}>Список материалов</h2>
          <ul style={{ listStyleType: "none", padding: 0 }}>
            {materials.map((material) => (
              <li
                key={material._id}
                style={{
                  background: "#f9f9f9",
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  padding: "20px",
                  marginBottom: "15px",
                  // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <h4 style={{ margin: 0 }}>{material.name}</h4>
                <p>ID: {material._id}</p>
                <p>Производитель: {material.manufacturer}</p>

                {/* Отображение иконки материала */}
                <p>
                  Иконка:{" "}
                  <img
                    src={material.icon}
                    alt={`${material.name} icon`}
                    style={{ maxWidth: "50px", borderRadius: "4px" }}
                  />
                </p>

                <button
                  onClick={() => startEditing(material)}
                  style={{ marginTop: "10px", marginRight: "10px" }}
                >
                  Редактировать
                </button>
                {/* <button
                  onClick={() => deleteMaterial(material._id)}
                  style={{ marginTop: "10px" }}
                >
                  Удалить материал
                </button> */}

                {/* Отображение этапов материала */}
                <h5 style={{ marginTop: "20px" }}>Этапы:</h5>
                <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                  {material.stages.map((stage, index) => (
                    <li
                      key={index}
                      style={{
                        borderTop: "1px solid #ddd",
                        paddingTop: "10px",
                      }}
                    >
                      <p>Этап: {stage.stage}</p>
                      <p>Расход на м²: {stage.consumptionPerSquareMeter}</p>
                      <p>Формула: {stage.formula}</p>
                      <p>Тип стены: {stage.wallType}</p>
                      <p>Тип шва: {stage.jointType}</p>
                      <p>Аналоги:</p>
                      <ul>
                        {stage.analogs.map((analog, analogIndex) => (
                          <li key={analogIndex}>
                            {analog.name} - {analog.manufacturer} ({analog._id})
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default AdminProductsPage;
