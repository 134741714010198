import React, { useEffect } from "react";
// import Header from "../components/Header";
import "../style/App.css";
import Sidebar from "../components/SideBar";

function LoadingScreen({ progress, setProgress }) {
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prevProgress + 1;
      });
    }, 30);

    return () => clearInterval(interval);
  }, [setProgress]);

  return (
    <div className="loading-screen-container">
      {/* <Header /> */}
      {/* <Sidebar/> */}
      <div className="loading-text">
        <span>Загрузка...</span>
        <span className="progress-percentage"><strong>{progress}%</strong></span>
      </div>
      <div className="progress-bar">
        <div className="progress" style={{ width: `${progress}%` }}></div>
      </div>
    </div>
  );
}

export default LoadingScreen;
