import React, { useRef, useEffect } from "react";
import "../style/App.css";
import deleteIcon from "../img/trash.svg";
import refreshIcon from "../img/refresh-2.svg";
import { useMaterials } from "../contexts/MaterialsContext";

function AnalogModal({ isOpen, onClose, clickPosition, item }) {
  const { removeMaterialFromResult, changeOnAnalog } = useMaterials();
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) {
    return null;
  }

  const modalStyles = {
    position: "absolute",
    top: clickPosition.y - 10 - (modalRef.current?.offsetHeight || 0),
    left: clickPosition.x - (modalRef.current?.offsetWidth || 293),
    zIndex: 1001,
  };

  const handleChangeMaterial = (stage, materialId, analogId) => {
    changeOnAnalog(stage, materialId, analogId);
  };

  return (
    <div className="analog-modal-overlay">
      <div className="analog-modal" style={modalStyles} ref={modalRef}>
        <div className="analog-modal-content">
          {item?.analogs?.map((analog) => (
            <div key={analog._id} className="analog-item">
              {analog.icon && (
                <img
                  src={analog.icon}
                  alt={analog.name}
                  className="analog-icon"
                />
              )}
              <div className="analog-details">
                <span className="analog-name">{analog.name}</span>
                <button
                  className="configurator-action-button"
                  onClick={() =>
                    handleChangeMaterial(item.stage, item.id, analog.id)
                  }
                >
                  <img src={refreshIcon} alt="Заменить" /> Заменить
                </button>
              </div>
            </div>
          ))}
          <button
            className="modal-delete"
            onClick={() => {
              removeMaterialFromResult(item.stage, item.id);
              onClose();
            }}
          >
            <img src={deleteIcon} alt="Удалить" className="delete-icon" />
            <span>Удалить</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default AnalogModal;
