import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./Login";
import Register from "./Register";
import NewCalculation from "./NewCalculation";
import MyPrices from "./MyPrices/MyPrices";
import PrivateRoute from "../Routes/PrivateRoute";
import PublicRoute from "../Routes/PublicRoute";
import "../style/App.css";
import { AuthProvider } from "../contexts/AuthContext";
import { TabProvider } from "../contexts/TabContext";
import Subscription from "./Subscribe";
import Profile from "./Profile";
import MaterialsPage from "./MaterialsPage";
import AdminProductsPage from "./AdminProductsPage";
import TermsAndConditionsPage from "../pages/TermsAndConditionsPage";
import PricingPage from "../pages/PricingPage";
import { MaterialsProvider } from "../contexts/MaterialsContext";
import FeedbackModal from "../components/FeedbackModal";
import feedbackIcon from "../img/message-text.svg";
import LandingPage from "./LandingPage";
import Header from "../components/Header";

const ROUTES = {
  LOGIN: "/login",
  REGISTER: "/register",
  NEW_CALCULATION: "/new-calculation",
  MY_PRICES: "/my-prices",
  SUBSCRIPTION: "/subscription",
  PROFILE: "/profile",
  MATERIALS: "/materials-analog",
  ADMIN_PRODUCTS: "/admin-products",
  LANDING: "/",
  TERMS: "/terms",
  PRIVACY: "/privacy",
  PRICING: "/pricing",
  DEFAULT: "/new-calculation",
};

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleFeedbackSubmit = (feedbackData) => {
    console.log("Feedback submitted:", feedbackData);
    handleCloseModal();
  };

  return (
    <AuthProvider>
      <TabProvider>
        <MaterialsProvider>
          <Router>
            <div className="App">
              <Routes>
                <Route path={ROUTES.LANDING} element={<LandingPage />} />
                <Route path={ROUTES.PRICING} element={<PricingPage />} />
                <Route
                  path={ROUTES.TERMS}
                  element={
                    <TermsAndConditionsPage title="Пользовательское соглашение" />
                  }
                />
                <Route
                  path={ROUTES.PRIVACY}
                  element={
                    <TermsAndConditionsPage title="Политика конфиденциальности" />
                  }
                />
                <Route
                  path={ROUTES.LOGIN}
                  element={
                    <PublicRoute restricted={true}>
                      <Login />
                    </PublicRoute>
                  }
                />
                <Route
                  path={ROUTES.REGISTER}
                  element={
                    <PublicRoute restricted={true}>
                      <Register />
                    </PublicRoute>
                  }
                />
                <Route
                  path={ROUTES.NEW_CALCULATION}
                  element={
                    <PrivateRoute>
                      <Header />
                      <NewCalculation />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={ROUTES.MY_PRICES}
                  element={
                    <PrivateRoute>
                      <Header />
                      <MyPrices />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={ROUTES.SUBSCRIPTION}
                  element={
                    <PrivateRoute>
                      <Header />
                      <Subscription />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={ROUTES.PROFILE}
                  element={
                    <PrivateRoute>
                      <Header />
                      <Profile />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={ROUTES.MATERIALS}
                  element={
                    <PrivateRoute>
                      <Header />
                      <MaterialsPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={ROUTES.ADMIN_PRODUCTS}
                  element={
                    <PrivateRoute>
                      <AdminProductsPage />
                    </PrivateRoute>
                  }
                />
                <Route path="*" element={<Navigate to={ROUTES.DEFAULT} />} />
              </Routes>

              {/* Кнопка для открытия модального окна обратной связи */}
              <button
                className="floating-feedback-button"
                onClick={handleOpenModal}
              >
                <img src={feedbackIcon} alt="Feedback" />
              </button>

              {/* Модальное окно обратной связи */}
              <FeedbackModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                onSubmit={handleFeedbackSubmit}
              />
            </div>
          </Router>
        </MaterialsProvider>
      </TabProvider>
    </AuthProvider>
  );
}

export default App;
