import React, { useState, useEffect, useCallback, useMemo } from "react";
import Results from "./Results";
import printIcon from "../img/printer.svg";
import closeModal from "../img/Close_MD.svg";
import { getUrl } from "./apiUrl";
import { motion } from "framer-motion";
import InfoPanel from "./InfoPanel";
import qIcon from "../img/info-circle.svg";
import sort from "../img/sort-amount-down.svg";
import ConfiguratorButton from "../components/ConfiguratorButton";
import { useMaterials } from "../contexts/MaterialsContext";
import * as XLSX from 'xlsx';
import ResultsGrouped from "./ResultsGrouped";

function ResultsStep({
  wallArea,
  tapeLength,
  selectedSeamType,
  selectedManufacturer,
  selectedWallSurface,
}) {
  //#region variables
  const {
    setMaterials,
    setJobs,
    calculatedJobs,
    calculatedMaterials,
    setSelectedWallSurface,
    setSelectedManufacturer,
    setSelectedSeamType,
    setWallArea,
  } = useMaterials();

  const [isCardVisible, setIsCardVisible] = useState(false);
  const [isWarningVisible, setIsWarningVisible] = useState(false);
  const [isInfoPanelOpen, setIsInfoPanelOpen] = useState(false);

  //#endregion

  //#region fetch data & logic
  useEffect(() => {
    const fetchMaterialsAndPrices = async () => {
      try {
        const apiUrl = getUrl();

        const [jobsResponse, pricesResponse] = await Promise.all([
          fetch(`${apiUrl}/api/jobs`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }),
          fetch(`${apiUrl}/api/job-price`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }),
        ]);

        if (!jobsResponse.ok || !pricesResponse.ok) {
          throw new Error("Failed to fetch jobs or prices");
        }

        const jobs = await jobsResponse.json();
        const prices = await pricesResponse.json();

        const mergedData = jobs.map((job) => ({
          ...job,
          price: prices.find((price) => price.job._id === job._id)?.price || 0,
        }));

        setJobs(mergedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchMaterialsAndPrices();
  }, []);

  useEffect(() => {
    const fetchMaterialsAndPrices = async () => {
      try {
        const apiUrl = getUrl();
        const manufacture = selectedManufacturer.toLowerCase();

        const [materialsResponse, pricesResponse] = await Promise.all([
          fetch(
            `${apiUrl}/api/materials/get-materials-by-manufacture/${manufacture}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          ),
          fetch(`${apiUrl}/api/material-price`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }),
        ]);

        if (!materialsResponse.ok || !pricesResponse.ok) {
          throw new Error("Failed to fetch materials or prices");
        }

        const materials = await materialsResponse.json();
        const prices = await pricesResponse.json();

        const mergedData = materials.map((material) => ({
          ...material,
          price:
            prices.find((price) => price?.material?._id === material?._id)
              ?.price || 0,
        }));

        setMaterials(mergedData, selectedSeamType, selectedManufacturer, selectedWallSurface);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchMaterialsAndPrices();
  }, [selectedManufacturer]);


  useEffect(() => {
    setSelectedManufacturer(selectedManufacturer);
    setSelectedSeamType(selectedSeamType);
  }, []);

  //#endregion

  //#region handlers & getters
  const downloadFile = () => {
    const formattedMaterials = calculatedMaterials.map(item => ({
      "Наименование материала": item.name,
      "Количество упаковок": item.units,
      "Единица измерения": item.unit,
      "Количество в упаковке": item.packaging,
      "Расход кг/м²": item.consumptionPerSquareMeter,
      "Цена за упаковку": item.price,
      "Общая стоимость": item.totalPrice
    }));

    const materialsWorksheet = XLSX.utils.json_to_sheet(formattedMaterials);

    const materialColumnWidths = [
      { wch: 30 },
      { wch: 20 },
      { wch: 15 },
      { wch: 20 },
      { wch: 15 },
      { wch: 15 },
      { wch: 20 } 
    ];
    materialsWorksheet['!cols'] = materialColumnWidths;

    // const formattedJobs = calculatedJobs.map(job => ({
    //   "Наименование работы": job.name,
    //   "Стоимость за м²": job.consumptionPerSquareMeter,
    //   "Общая стоимость": job.totalPrice
    // }));
  
    // const jobsWorksheet = XLSX.utils.json_to_sheet(formattedJobs);
  
    // const jobsColumnWidths = [
    //   { wch: 30 },
    //   { wch: 15 },
    //   { wch: 20 } 
    // ];
    // jobsWorksheet['!cols'] = jobsColumnWidths;

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, materialsWorksheet, 'Материалы');
    // XLSX.utils.book_append_sheet(workbook, jobsWorksheet, 'Работы');

    XLSX.writeFile(workbook, 'Смета.xlsx');
  };

  const handleCloseWarning = () => {
    setIsWarningVisible(false); // Close the warning card
  };

  const getWallSurfaceLabel = (option) => {
    if (option === "plaster") {
      return "Штукатурка";
    } else if (option === "drywall") {
      return "Гипсокартон";
    }
    return "";
  };

  const getManufacterLabel = (option) => {
    if (option === "Smit") {
      return "СМиТ";
    } else if (option === "Knauf") {
      return "Knauf";
    }
    return "";
  };

  const getSeamLabel = (option) => {
    if (option === "UK") {
      return "Шов УК";
    } else if (option === "PLUK") {
      return "Шов ПЛУК";
    }
    return "-";
  };

  const handleOpenPanel = () => {
    setIsInfoPanelOpen(true);
  };

  const handleClosePanel = () => {
    setIsInfoPanelOpen(false);
  };

  //#endregion

  return (
    <div className="results-container">
      <ConfiguratorButton />
      <InfoPanel isOpen={isInfoPanelOpen} onClose={handleClosePanel} />
      <div className="summary-card">
        <div className="summary-actions">
          <h3>Расчет</h3>
          <div className="summary-action-btns">
            <button className="summary-action-button" onClick={handleOpenPanel}>
              <img
                className="summary-icon"
                src={qIcon}
                alt="Что такое Q1-Q4?"
              />
              <label className="summary-label">Что такое Q1-Q4?</label>
            </button>
            <button
              className="summary-action-button red-btn"
              onClick={downloadFile}
            >
              <img
                className="summary-icon"
                src={printIcon}
                alt="Скачать"
              />
              <label className="summary-label">Скачать</label>
            </button>
          </div>
        </div>
        <div className="summary-card-total">
          <div className="summary-item">
            <span className="summary-title">Производитель</span>
            <span className="summary-value">
              {getManufacterLabel(selectedManufacturer)}
            </span>
          </div>
          <div className="summary-item">
            <span className="summary-title">Тип шва</span>
            <span className="summary-value">
              {getSeamLabel(selectedSeamType)}
            </span>
          </div>
          <div className="summary-item">
            <span className="summary-title">Площадь поверхности</span>
            <span className="summary-value">{wallArea} м²</span>
          </div>
          <div className="summary-item">
            <span className="summary-title">Покрытие стен</span>
            <span className="summary-value">
              {getWallSurfaceLabel(selectedWallSurface)}
            </span>
          </div>
        </div>
      </div>

      <div className="custom-scrollbar">
        <Results
          title="Материалы"
          items={calculatedMaterials}
          count={calculatedMaterials.length}
        />
        <ResultsGrouped />
        {/* <Results
          title="Работы"
          items={calculatedJobs}
          count={calculatedJobs.length}
        /> */}

        {/* {isCardVisible && (
          <div className="total-card-overlay" onClick={handleOutsideClick}>
            <motion.div
              className="total-card-content"
              initial={{ x: "100%", opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: "100%", opacity: 0 }}
              transition={{ duration: 0.3 }}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="total-header">
                <h2 className="total-card-title">Смета</h2>
                <div className="total-buttons">
                  <button className="total-card-print" onClick={handlePrint}>
                    <img
                      className="total-card-icon"
                      src={printIcon}
                      alt="Смета"
                    />
                    <label className="total-label">Распечатать</label>
                  </button>
                  <button className="close-button" onClick={handleCloseCard}>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.833 5.833L14.166 14.167M5.833 14.167L14.166 5.833"
                        stroke="#0D0D0D"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>

              <div className="total-results-content">
                <div className="total-results-header">
                  <div className="total-details">
                    <div className="total-detail">
                      <img
                        className="total-card-icon"
                        src={sort}
                        alt="Название"
                      />
                      <span className="total-item-name">Название</span>
                    </div>
                    <div className="total-detail-right">
                      <div className="total-detail">
                        <span className="total-item-name">Цена шт</span>
                      </div>
                      <div className="total-detail">
                        <span className="total-item-name">Кол-во</span>
                      </div>
                      <div className="total-detail">
                        <span className="total-item-name">Сумма2222222222</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="total-items-list">
                  {calculatedMaterials.map((material, index) => (
                    <div className="total-item" key={index}>
                      <div className="total-details">
                        <div className="total-detail">
                          <span className="total-item-name">
                            {material.name}
                          </span>
                        </div>
                        <div className="total-detail-right">
                          <div className="total-detail">
                            <span className="total-value">
                              {material.price?.toLocaleString()} Р
                            </span>
                          </div>
                          <div className="total-detail">
                            <span className="total-value">
                              {material.units} шт.
                            </span>
                          </div>
                          <div className="total-detail">
                            <span className="total-value">
                              {(
                                material.price * material.units
                              )?.toLocaleString()}{" "}
                              Р
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="total-summary">
                  <strong>Итого:</strong>
                  <div className="total-info">
                    <span>{getTotalQuantity()} шт.</span>
                    <span>{getTotalAmount().toLocaleString()} Р</span>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        )} */}
      </div>

      {isWarningVisible && (
        <div className="warning-overlay" onClick={handleCloseWarning}>
          <div className="warning-card" onClick={(e) => e.stopPropagation()}>
            <div className="warning-card-header">
              <h2>Упс!</h2>
              <button className="warning-card-btn" onClick={handleCloseWarning}>
                <img
                  className="warning-card-icon"
                  src={closeModal}
                  alt="Закрыть"
                />
              </button>
            </div>
            <p>
              Прежде чем получить смету, необходимо заполнить информацию в
              разделе “Мои цены”.
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default ResultsStep;
