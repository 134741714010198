import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import "../style/App.css";
import { useAuth } from "../contexts/AuthContext";
import FeedbackModal from "./FeedbackModal";
import { getUrl } from "./apiUrl";
import logo from "../img/Logo-circle.svg";
import grFlash from "../img/flash-green.svg";
import UpgradeModal from "./UpgradeModal";

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const userId = JSON.parse(localStorage.getItem("user"))?.id;

  const handleNavigation = (path) => {
    navigate(path);
  };

  const isActive = (path) => location.pathname === path;

  const handleFeedbackSubmit = async (feedback) => {
    try {
      const apiUrl = getUrl();
      const response = await fetch(`${apiUrl}/api/feedback/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ userId, feedback }),
      });
      if (response.ok) {
        alert("Ваше сообщение отправлено.");
        setIsFeedbackOpen(false);
      } else {
        alert("Ошибка отправки сообщения.");
      }
    } catch (error) {
      console.error("Error sending feedback:", error);
      alert("Ошибка соединения с сервером.");
    }
  };

  const handleOpenModal = () => {
    setIsUpgradeModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsUpgradeModalOpen(false);
  };

  return (
    <aside className="sidebar">
      <div className="sidebar-top">
        <div className="sidebar-top-wrapper">
        <div className="sidebar-header">
          <img src={logo} className="sidebar-logo" alt="logo" />
          <div className="sidebar-text">
            <h3>Территория маляров</h3>
            <p>От мастера мастеру</p>
          </div>
        </div>

        <nav className="sidebar-menu">
          <ul>
            <li
              className={`sidebar-li ${
                isActive("/new-calculation") ? "active" : ""
              }`}
            >
              <button
                className="sidebar-a"
                onClick={() => handleNavigation("/new-calculation")}
              >
                <svg
                  className="menu-icon"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.33333 18.3332H11.6667C15.8333 18.3332 17.5 16.6665 17.5 12.4998V7.49984C17.5 3.33317 15.8333 1.6665 11.6667 1.6665H8.33333C4.16667 1.6665 2.5 3.33317 2.5 7.49984V12.4998C2.5 16.6665 4.16667 18.3332 8.33333 18.3332Z"
                    stroke="#737373"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13.75 6.31689V7.15023C13.75 7.83356 13.1917 8.40023 12.5 8.40023H7.5C6.81667 8.40023 6.25 7.84189 6.25 7.15023V6.31689C6.25 5.63356 6.80833 5.06689 7.5 5.06689H12.5C13.1917 5.06689 13.75 5.62523 13.75 6.31689Z"
                    stroke="#737373"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6.7801 11.6667H6.78973"
                    stroke="#737373"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.99604 11.6667H10.0057"
                    stroke="#737373"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13.212 11.6667H13.2216"
                    stroke="#737373"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6.7801 14.5832H6.78973"
                    stroke="#737373"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.99604 14.5832H10.0057"
                    stroke="#737373"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13.212 14.5832H13.2216"
                    stroke="#737373"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Расчет
              </button>
            </li>
            <li
              className={`sidebar-li ${isActive("/my-prices") ? "active" : ""}`}
            >
              <button
                className="sidebar-a"
                onClick={() => handleNavigation("/my-prices")}
              >
                <svg
                  className="menu-icon"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.0002 18.9582C6.66683 18.9582 3.9585 16.5665 3.9585 13.6248V10.5415C3.9585 10.1998 4.24183 9.9165 4.5835 9.9165C4.92516 9.9165 5.2085 10.1998 5.2085 10.5415C5.2085 12.7248 7.26683 14.3748 10.0002 14.3748C12.7335 14.3748 14.7918 12.7248 14.7918 10.5415C14.7918 10.1998 15.0752 9.9165 15.4168 9.9165C15.7585 9.9165 16.0418 10.1998 16.0418 10.5415V13.6248C16.0418 16.5665 13.3335 18.9582 10.0002 18.9582ZM5.2085 13.7165C5.26683 15.9248 7.39183 17.7082 10.0002 17.7082C12.6085 17.7082 14.7335 15.9248 14.7918 13.7165C13.7085 14.8915 11.9918 15.6248 10.0002 15.6248C8.0085 15.6248 6.30016 14.8915 5.2085 13.7165Z"
                    fill="#737373"
                  />
                  <path
                    d="M10.0002 11.4582C7.70016 11.4582 5.63349 10.4248 4.62515 8.75817C4.19182 8.04984 3.9585 7.22484 3.9585 6.37484C3.9585 4.9415 4.60017 3.5915 5.7585 2.57484C6.89183 1.58317 8.40016 1.0415 10.0002 1.0415C11.6002 1.0415 13.1002 1.58317 14.2418 2.56651C15.4002 3.59151 16.0418 4.9415 16.0418 6.37484C16.0418 7.22484 15.8085 8.0415 15.3752 8.75817C14.3668 10.4248 12.3002 11.4582 10.0002 11.4582ZM10.0002 2.2915C8.70016 2.2915 7.48351 2.72484 6.57517 3.52484C5.69184 4.2915 5.2085 5.30817 5.2085 6.37484C5.2085 6.99984 5.37515 7.58317 5.69182 8.10817C6.48348 9.40817 8.1335 10.2082 10.0002 10.2082C11.8668 10.2082 13.5168 9.39984 14.3085 8.10817C14.6335 7.58317 14.7918 6.99984 14.7918 6.37484C14.7918 5.30817 14.3085 4.2915 13.4168 3.50817C12.5085 2.72484 11.3002 2.2915 10.0002 2.2915Z"
                    fill="#737373"
                  />
                  <path
                    d="M10.0002 15.6248C6.5585 15.6248 3.9585 13.4415 3.9585 10.5415V6.37484C3.9585 3.43317 6.66683 1.0415 10.0002 1.0415C11.6002 1.0415 13.1002 1.58317 14.2418 2.56651C15.4002 3.59151 16.0418 4.9415 16.0418 6.37484V10.5415C16.0418 13.4415 13.4418 15.6248 10.0002 15.6248ZM10.0002 2.2915C7.3585 2.2915 5.2085 4.12484 5.2085 6.37484V10.5415C5.2085 12.7248 7.26683 14.3748 10.0002 14.3748C12.7335 14.3748 14.7918 12.7248 14.7918 10.5415V6.37484C14.7918 5.30817 14.3085 4.2915 13.4168 3.50817C12.5085 2.72484 11.3002 2.2915 10.0002 2.2915Z"
                    fill="#737373"
                  />
                </svg>
                Мои цены
              </button>
            </li>
            <li
              className={`sidebar-li ${
                isActive("/subscription") ? "active" : ""
              }`}
            >
              <button
                className="sidebar-a"
                onClick={() => handleNavigation("/subscription")}
              >
                <svg
                  className="menu-icon"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.15005 18.6502C7.92005 18.6502 7.69005 18.6169 7.47005 18.5502C6.72005 18.3169 6.24004 17.7335 6.24004 17.0669V11.6919H3.90005C3.14005 11.6919 2.48004 11.3335 2.17004 10.7585C1.86004 10.1835 1.98003 9.53355 2.48003 9.05855L10.05 1.89188C10.58 1.39188 11.4 1.21688 12.15 1.45855C12.9 1.69188 13.38 2.27522 13.38 2.94188V8.31688H15.7201C16.4801 8.31688 17.14 8.67522 17.45 9.25022C17.76 9.82522 17.64 10.4752 17.14 10.9502L9.57003 18.1169C9.20003 18.4669 8.68005 18.6502 8.15005 18.6502ZM11.46 2.60022C11.37 2.60022 11.26 2.62522 11.17 2.71688L3.60003 9.89188C3.44003 10.0419 3.50005 10.1919 3.53005 10.2502C3.56005 10.3085 3.66005 10.4502 3.90005 10.4502H6.99004C7.40004 10.4502 7.74004 10.7336 7.74004 11.0752V17.0752C7.74004 17.2835 7.92005 17.3669 8.00005 17.3919C8.08005 17.4169 8.28003 17.4585 8.44003 17.3002L16.01 10.1252C16.17 9.97522 16.11 9.82522 16.08 9.76688C16.05 9.70855 15.95 9.56688 15.71 9.56688H12.62C12.21 9.56688 11.87 9.28355 11.87 8.94188V2.94188C11.87 2.73355 11.69 2.65022 11.61 2.62522C11.58 2.60855 11.52 2.60022 11.46 2.60022Z"
                    fill="#737373"
                  />
                </svg>
                Подписка
              </button>
            </li>
            <li
              className={`sidebar-li ${isActive("/profile") ? "active" : ""}`}
            >
              <button
                className="sidebar-a"
                onClick={() => handleNavigation("/profile")}
              >
                <svg
                  className="menu-icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Iconly/Light/Profile">
                    <g id="Profile">
                      <path
                        id="Stroke 1"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.9847 15.3462C8.11707 15.3462 4.81421 15.931 4.81421 18.2729C4.81421 20.6148 8.09611 21.2205 11.9847 21.2205C15.8523 21.2205 19.1542 20.6348 19.1542 18.2938C19.1542 15.9529 15.8733 15.3462 11.9847 15.3462Z"
                        stroke="#636366"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        id="Stroke 3"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.9846 12.0059C14.5227 12.0059 16.5799 9.94779 16.5799 7.40969C16.5799 4.8716 14.5227 2.81445 11.9846 2.81445C9.44655 2.81445 7.38845 4.8716 7.38845 7.40969C7.37988 9.93922 9.42369 11.9973 11.9523 12.0059H11.9846Z"
                        stroke="#636366"
                        strokeWidth="1.42857"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                  </g>
                </svg>
                Профиль
              </button>
            </li>
          </ul>
        </nav>
        </div>

        <div className="progress-container">
          <div className="progress-info">
            <span>
              Сделано 0 из 4 расчетов
            </span>
            <div className="progress-bar sidebar-progress">
              <div
                className="progress"
                style={{ width: `${progressPercentage}%` }}
              ></div>
            </div>
          </div>
          <div>
      <button className="improve-button" onClick={handleOpenModal}>
        Улучшить
        <img src={grFlash} className="improve-icon" alt="flash-icon" />
      </button>

      <UpgradeModal isOpen={isUpgradeModalOpen} onClose={handleCloseModal} />
    </div>
        </div>
      </div>

      <div className="underline"></div>

      <div className="lower-sidebar">
        <div className="support-section">
          <h3>План проекта гипсокартонного потолка за 2 минуты</h3>
          <p>
            Опробуйте алгоритм самостоятельно, на данный момент создание
            проектов производится абсолютно бесплатно.
          </p>
          <button
            className="secondary-button sidebar-btn"
            onClick={() => window.open("https://plan.itstroyka.com/editor", "_blank")}
          >
            Попробовать
          </button>
        </div>

        <div className="sidebar-footer">
          <button
            className="sidebar-link"
            onClick={() => handleNavigation("/terms")}
          >
            Пользовательское соглашение
          </button>
          <button
            className="sidebar-link"
            onClick={() => handleNavigation("/privacy")}
          >
            Политика конфиденциальности
          </button>
          <p>ООО “Территория Моляров” 2024</p>
        </div>
      </div>

      <FeedbackModal
        isOpen={isFeedbackOpen}
        onClose={() => setIsFeedbackOpen(false)}
        onSubmit={handleFeedbackSubmit}
        userId={userId}
      />
    </aside>
  );
};

export default Sidebar;
