import React from "react";
import HeaderLanding from "../components/HeaderLanding";
import FooterLanding from "../components/FooterLanding";
import TermsandConditions from "../components/TermsandConditions";
import "../style/Landing.css";

const TermsAndConditionsPage = ({ title }) => {

  const agreementContent = [
    {
      header: "Header",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dignissim sed mauris pharetra ipsum tincidunt sit. Lorem congue nam quis sapien. Facilisi eleifend dolor lorem erat sapien urna, aliquet pulvinar. Morbi lorem molestie elementum odio placerat. Scelerisque ac, imperdiet lacus integer elit. Egestas sit at curabitur sagittis, vel. Tellus nisl quis habitant sapien purus, ultricies in sed. Feugiat turpis justo, rutrum dolor nibh ullamcorper. Sed est sed id duis diam vitae. Quis ut consequat donec habitasse egestas vulputate pulvinar. Sed eleifend massa curabitur auctor natoque. Id morbi sapien ridiculus id nisl. Aliquet interdum quam tincidunt maecenas lacinia sed eget venenatis bibendum. In bibendum sed vitae eu et. Tempus diam, viverra vehicula imperdiet lorem vestibulum netus consequat tempor.",
      subsections: [
        {
          subhead: "1.0 Subhead",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dignissim sed mauris pharetra ipsum tincidunt sit. Lorem congue nam quis sapien. Facilisi eleifend dolor lorem erat sapien urna, aliquet pulvinar. Morbi lorem molestie elementum odio placerat. Scelerisque ac, imperdiet lacus integer elit. Egestas sit at curabitur sagittis, vel. Tellus nisl quis habitant sapien purus, ultricies in sed. Feugiat turpis justo, rutrum dolor nibh ullamcorper. Sed est sed id duis diam vitae. Quis ut consequat donec habitasse egestas vulputate pulvinar. Sed eleifend massa curabitur auctor natoque. Id morbi sapien ridiculus id nisl. Aliquet interdum quam tincidunt maecenas lacinia sed eget venenatis bibendum. In bibendum sed vitae eu et. Tempus diam, viverra vehicula imperdiet lorem vestibulum netus consequat tempor.",
        },
        {
          subhead: "2.0 Subhead",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dignissim sed mauris pharetra ipsum tincidunt sit. Lorem congue nam quis sapien. Facilisi eleifend dolor lorem erat sapien urna, aliquet pulvinar. Morbi lorem molestie elementum odio placerat. Scelerisque ac, imperdiet lacus integer elit. Egestas sit at curabitur sagittis, vel. Tellus nisl quis habitant sapien purus, ultricies in sed. Feugiat turpis justo, rutrum dolor nibh ullamcorper. Sed est sed id duis diam vitae. Quis ut consequat donec habitasse egestas vulputate pulvinar. Sed eleifend massa curabitur auctor natoque. Id morbi sapien ridiculus id nisl. Aliquet interdum quam tincidunt maecenas lacinia sed eget venenatis bibendum. In bibendum sed vitae eu et. Tempus diam, viverra vehicula imperdiet lorem vestibulum netus consequat tempor.",
        },
      ],
    },
    {
      header: "Header",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dignissim sed mauris pharetra ipsum tincidunt sit. Lorem congue nam quis sapien. Facilisi eleifend dolor lorem erat sapien urna, aliquet pulvinar. Morbi lorem molestie elementum odio placerat. Scelerisque ac, imperdiet lacus integer elit. Egestas sit at curabitur sagittis, vel. Tellus nisl quis habitant sapien purus, ultricies in sed. Feugiat turpis justo, rutrum dolor nibh ullamcorper. Sed est sed id duis diam vitae. Quis ut consequat donec habitasse egestas vulputate pulvinar. Sed eleifend massa curabitur auctor natoque. Id morbi sapien ridiculus id nisl. Aliquet interdum quam tincidunt maecenas lacinia sed eget venenatis bibendum. In bibendum sed vitae eu et. Tempus diam, viverra vehicula imperdiet lorem vestibulum netus consequat tempor.",
      subsections: [
        {
          subhead: "1.0 Subhead",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dignissim sed mauris pharetra ipsum tincidunt sit. Lorem congue nam quis sapien. Facilisi eleifend dolor lorem erat sapien urna, aliquet pulvinar. Morbi lorem molestie elementum odio placerat. Scelerisque ac, imperdiet lacus integer elit. Egestas sit at curabitur sagittis, vel. Tellus nisl quis habitant sapien purus, ultricies in sed. Feugiat turpis justo, rutrum dolor nibh ullamcorper. Sed est sed id duis diam vitae. Quis ut consequat donec habitasse egestas vulputate pulvinar. Sed eleifend massa curabitur auctor natoque. Id morbi sapien ridiculus id nisl. Aliquet interdum quam tincidunt maecenas lacinia sed eget venenatis bibendum. In bibendum sed vitae eu et. Tempus diam, viverra vehicula imperdiet lorem vestibulum netus consequat tempor.",
        },
        {
          subhead: "2.0 Subhead",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dignissim sed mauris pharetra ipsum tincidunt sit. Lorem congue nam quis sapien. Facilisi eleifend dolor lorem erat sapien urna, aliquet pulvinar. Morbi lorem molestie elementum odio placerat. Scelerisque ac, imperdiet lacus integer elit. Egestas sit at curabitur sagittis, vel. Tellus nisl quis habitant sapien purus, ultricies in sed. Feugiat turpis justo, rutrum dolor nibh ullamcorper. Sed est sed id duis diam vitae. Quis ut consequat donec habitasse egestas vulputate pulvinar. Sed eleifend massa curabitur auctor natoque. Id morbi sapien ridiculus id nisl. Aliquet interdum quam tincidunt maecenas lacinia sed eget venenatis bibendum. In bibendum sed vitae eu et. Tempus diam, viverra vehicula imperdiet lorem vestibulum netus consequat tempor.",
        },
      ],
    },
  ];
  
  const privacyContent = [
    {
      header: "Header",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dignissim sed mauris pharetra ipsum tincidunt sit. Lorem congue nam quis sapien. Facilisi eleifend dolor lorem erat sapien urna, aliquet pulvinar. Morbi lorem molestie elementum odio placerat. Scelerisque ac, imperdiet lacus integer elit. Egestas sit at curabitur sagittis, vel. Tellus nisl quis habitant sapien purus, ultricies in sed. Feugiat turpis justo, rutrum dolor nibh ullamcorper. Sed est sed id duis diam vitae. Quis ut consequat donec habitasse egestas vulputate pulvinar. Sed eleifend massa curabitur auctor natoque. Id morbi sapien ridiculus id nisl. Aliquet interdum quam tincidunt maecenas lacinia sed eget venenatis bibendum. In bibendum sed vitae eu et. Tempus diam, viverra vehicula imperdiet lorem vestibulum netus consequat tempor.",
      subsections: [
        {
          subhead: "1.0 Subhead",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dignissim sed mauris pharetra ipsum tincidunt sit. Lorem congue nam quis sapien. Facilisi eleifend dolor lorem erat sapien urna, aliquet pulvinar. Morbi lorem molestie elementum odio placerat. Scelerisque ac, imperdiet lacus integer elit. Egestas sit at curabitur sagittis, vel. Tellus nisl quis habitant sapien purus, ultricies in sed. Feugiat turpis justo, rutrum dolor nibh ullamcorper. Sed est sed id duis diam vitae. Quis ut consequat donec habitasse egestas vulputate pulvinar. Sed eleifend massa curabitur auctor natoque. Id morbi sapien ridiculus id nisl. Aliquet interdum quam tincidunt maecenas lacinia sed eget venenatis bibendum. In bibendum sed vitae eu et. Tempus diam, viverra vehicula imperdiet lorem vestibulum netus consequat tempor.",
        },
        {
          subhead: "2.0 Subhead",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dignissim sed mauris pharetra ipsum tincidunt sit. Lorem congue nam quis sapien. Facilisi eleifend dolor lorem erat sapien urna, aliquet pulvinar. Morbi lorem molestie elementum odio placerat. Scelerisque ac, imperdiet lacus integer elit. Egestas sit at curabitur sagittis, vel. Tellus nisl quis habitant sapien purus, ultricies in sed. Feugiat turpis justo, rutrum dolor nibh ullamcorper. Sed est sed id duis diam vitae. Quis ut consequat donec habitasse egestas vulputate pulvinar. Sed eleifend massa curabitur auctor natoque. Id morbi sapien ridiculus id nisl. Aliquet interdum quam tincidunt maecenas lacinia sed eget venenatis bibendum. In bibendum sed vitae eu et. Tempus diam, viverra vehicula imperdiet lorem vestibulum netus consequat tempor.",
        },
      ],
    },
    {
      header: "Header",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dignissim sed mauris pharetra ipsum tincidunt sit. Lorem congue nam quis sapien. Facilisi eleifend dolor lorem erat sapien urna, aliquet pulvinar. Morbi lorem molestie elementum odio placerat. Scelerisque ac, imperdiet lacus integer elit. Egestas sit at curabitur sagittis, vel. Tellus nisl quis habitant sapien purus, ultricies in sed. Feugiat turpis justo, rutrum dolor nibh ullamcorper. Sed est sed id duis diam vitae. Quis ut consequat donec habitasse egestas vulputate pulvinar. Sed eleifend massa curabitur auctor natoque. Id morbi sapien ridiculus id nisl. Aliquet interdum quam tincidunt maecenas lacinia sed eget venenatis bibendum. In bibendum sed vitae eu et. Tempus diam, viverra vehicula imperdiet lorem vestibulum netus consequat tempor.",
      subsections: [
        {
          subhead: "1.0 Subhead",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dignissim sed mauris pharetra ipsum tincidunt sit. Lorem congue nam quis sapien. Facilisi eleifend dolor lorem erat sapien urna, aliquet pulvinar. Morbi lorem molestie elementum odio placerat. Scelerisque ac, imperdiet lacus integer elit. Egestas sit at curabitur sagittis, vel. Tellus nisl quis habitant sapien purus, ultricies in sed. Feugiat turpis justo, rutrum dolor nibh ullamcorper. Sed est sed id duis diam vitae. Quis ut consequat donec habitasse egestas vulputate pulvinar. Sed eleifend massa curabitur auctor natoque. Id morbi sapien ridiculus id nisl. Aliquet interdum quam tincidunt maecenas lacinia sed eget venenatis bibendum. In bibendum sed vitae eu et. Tempus diam, viverra vehicula imperdiet lorem vestibulum netus consequat tempor.",
        },
        {
          subhead: "2.0 Subhead",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dignissim sed mauris pharetra ipsum tincidunt sit. Lorem congue nam quis sapien. Facilisi eleifend dolor lorem erat sapien urna, aliquet pulvinar. Morbi lorem molestie elementum odio placerat. Scelerisque ac, imperdiet lacus integer elit. Egestas sit at curabitur sagittis, vel. Tellus nisl quis habitant sapien purus, ultricies in sed. Feugiat turpis justo, rutrum dolor nibh ullamcorper. Sed est sed id duis diam vitae. Quis ut consequat donec habitasse egestas vulputate pulvinar. Sed eleifend massa curabitur auctor natoque. Id morbi sapien ridiculus id nisl. Aliquet interdum quam tincidunt maecenas lacinia sed eget venenatis bibendum. In bibendum sed vitae eu et. Tempus diam, viverra vehicula imperdiet lorem vestibulum netus consequat tempor.",
        },
      ],
    },
  ];

  const content = title === "Политика конфиденциальности" ? privacyContent : agreementContent;

  return (
    <div className="tnc-container">
      <HeaderLanding />
      <div className="content-section">
        <TermsandConditions title={title} content={content} />
      </div>
      <FooterLanding />
    </div>
  );
};

export default TermsAndConditionsPage;
