import React, { useState, useEffect } from "react";
import "../style/Landing.css";
import { useNavigate } from "react-router-dom";
import LandingMainContent from "../components/LandingMainContent";
import HeaderLanding from "../components/HeaderLanding";
import FooterLanding from "../components/FooterLanding";

function LandingPage() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="landing-page">
      <HeaderLanding 
        toggleMenu={toggleMenu} 
        isMenuOpen={isMenuOpen} 
      />

      <main>
        <LandingMainContent />
      </main>

      <FooterLanding />
    </div>
  );
}

export default LandingPage;
