import React, { useState, useEffect, useRef } from "react";
import "../style/App.css";
import { useNavigate } from "react-router-dom";
import ManufacturerStep from "../components/ManufacturerStep";
import SeamStep from "../components/SeamStep";
import CalculatorStep from "../components/CalculatorStep";
import ResultsStep from "../components/ResultStep";
import LoadingScreen from "../components/LoadingScreen";
import Sidebar from "../components/SideBar";
import Warning from "../components/warningCard";
import { useTab } from "../contexts/TabContext";
import arrowR from "../img/fi_chevron-right.svg";
import arrowL from "../img/fi_chevron-left.svg";


function NewCalculation() {
  const navigate = useNavigate();
  const { activeTab, setActiveTab } = useTab();
  const [isLoading, setIsLoading] = useState(false);
  const [isCalculated, setIsCalculated] = useState(false);
  const [progress, setProgress] = useState(0);
  const [wallArea, setWallArea] = useState(300);
  const [tapeLength, setTapeLength] = useState(150);
  const [plasterboardArea, setPlasterboardArea] = useState(24);
  const [selectedManufacturer, setSelectedManufacturer] = useState("");
  const [selectedSeam, setSelectedSeam] = useState("none");
  const [selectedOption, setSelectedOption] = useState("plaster");

  const tabRefs = useRef([]);
  const [highlightStyle, setHighlightStyle] = useState({});

  // Получаем список табов с динамическим исключением "Шов", если выбрана "штукатурка"
  const getTabs = () => {
    const tabs = ["Производитель", "Размеры"];
    if (selectedOption !== "plaster") {
      tabs.push("Шов");
    }
    tabs.push("Расчет");
    return tabs;
  };

  useEffect(() => {
    if (selectedOption === "plaster") setSelectedSeam("none");
  }, [selectedOption]);

  const handleTabClick = (tab) => {
    // Запрещаем переход на "Расчет", если выбрана "ГСП" и не выбран тип шва
    if (tab === "Расчет" && selectedOption === "drywall" && !selectedSeam) {
      alert("Выберите тип шва для гипсокартона (ГСП) перед расчетом!");
      return;
    }
    if (["Размеры", "Шов", "Расчет"].includes(tab) && !selectedManufacturer)
      return;

    setActiveTab(tab);
  };

  // Эффект для управления подсветкой активного таба
  useEffect(() => {
    const tabs = getTabs();
    const activeTabIndex = tabs.indexOf(activeTab);
    const activeTabElement = tabRefs.current[activeTabIndex];

    if (activeTabElement) {
      const tabWidth = activeTabElement.offsetWidth;
      const tabOffsetLeft = activeTabElement.offsetLeft;

      setHighlightStyle({
        width: `${tabWidth}px`,
        transform: `translateX(${tabOffsetLeft}px)`,
        height: "100%",
      });
    }
  }, [activeTab, selectedOption]);

  // Обработка перехода на следующий таб
  const handleNext = () => {
    const tabs = getTabs();
    const currentIndex = tabs.indexOf(activeTab);

    if (!selectedManufacturer) {
      alert(
        "Пожалуйста, выберите производителя перед переходом к следующему шагу!"
      );
      return;
    }

    if (
      activeTab === "Размеры" &&
      selectedOption === "drywall" &&
      !selectedSeam
    ) {
      alert("Выберите тип шва для гипсокартона (ГСП) перед продолжением!");
      return;
    }

    if (activeTab === "Шов" && selectedOption === "drywall" && !selectedSeam) {
      alert(
        "Пожалуйста, выберите тип шва для гипсокартона (ГСП) перед расчетом!"
      );
      return;
    }

    if (activeTab === "Шов" && selectedOption === "drywall" && !selectedSeam) {
      alert(
        "Пожалуйста, выберите тип шва для гипсокартона (ГСП) перед расчетом!"
      );
      return;
    }

    const nextTab = tabs[currentIndex + 1];

    if (nextTab === "Расчет") {
      handleCalculate();
    } else if (nextTab) {
      setActiveTab(nextTab);
    }
  };

  // Обработка перехода на предыдущий таб
  const handleBack = () => {
    const tabs = getTabs();
    const currentIndex = tabs.indexOf(activeTab);
    const prevTab = tabs[currentIndex - 1];
    if (prevTab) setActiveTab(prevTab);
  };

  const handleCalculate = async () => {
    if (selectedOption === "drywall" && !selectedSeam) {
      alert("Выберите тип шва для гипсокартона (ГСП)!");
      return;
    }
  
    setIsLoading(true); // Показываем экран загрузки
  
    // Здесь может быть ваш процесс расчета, например запрос на сервер
    try {
      // Пример асинхронного расчета - замените на реальный расчет или запрос
      await new Promise((resolve) => setTimeout(resolve, 2000)); // Увеличьте время, если загрузка занимает больше времени
  
      setIsCalculated(true); // Отметка, что расчет завершен
      setActiveTab("Расчет"); // Переход на вкладку "Расчет"
    } catch (error) {
      console.error("Error during calculation:", error);
      alert("Ошибка при выполнении расчета.");
    } finally {
      setIsLoading(false); // Убираем экран загрузки только после завершения расчета
    }
  };
  

  // Хендлер выбора производителя
  const handleManufacturerSelection = (manufacturer) =>
    setSelectedManufacturer(manufacturer);

  // Хендлер выбора типа шва
  const handleSeamSelection = (seam) => setSelectedSeam(seam);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate("/login");
  };

  // Получение подзаголовка для активного таба
  const getHeaderSubtitle = () => {
    switch (activeTab) {
      case "Производитель":
        return "Для продолжения выберите производителя";
      case "Размеры":
        return "Заполните размеры";
      case "Шов":
        return "Для продолжения выберите тип шва";
      case "Расчет":
        return isCalculated ? "Расчет готов" : "Заполните данные для расчета";
      default:
        return "";
    }
  };

  return (
    <div className="container">

      {isLoading && (
        <LoadingScreen progress={progress} setProgress={setProgress} />
      )}
      <Sidebar handleLogout={handleLogout} />
      <main className="main-content">
        <div className="main-top-content">
          <header className="main-header">
            <h1>Новый расчет</h1>
            <p>{getHeaderSubtitle()}</p>
          </header>

          <nav className="tab-menu">
            {getTabs().map((tab, index) => (
              <button
                key={tab}
                className={`tab ${activeTab === tab ? "active" : ""}`}
                onClick={() => handleTabClick(tab)}
                ref={(el) => (tabRefs.current[index] = el)}
              >
                {tab}
              </button>
            ))}
            <div className="tab-highlight" style={highlightStyle}></div>
          </nav>

          {activeTab === "Производитель" && (
            <ManufacturerStep
              handleManufacturerSelection={handleManufacturerSelection}
            />
          )}
          {activeTab === "Шов" && (
            <SeamStep handleSeamSelection={handleSeamSelection} />
          )}
          {activeTab === "Размеры" && (
            <CalculatorStep
              wallArea={wallArea}
              tapeLength={tapeLength}
              plasterboardArea={plasterboardArea}
              setWallArea={setWallArea}
              setTapeLength={setTapeLength}
              setPlasterboardArea={setPlasterboardArea}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
            />
          )}
          {!isLoading && activeTab === "Расчет" && (
            <ResultsStep
              wallArea={wallArea}
              tapeLength={tapeLength}
              plasterboardArea={plasterboardArea}
              selectedManufacturer={selectedManufacturer}
              selectedSeamType={selectedSeam}
              selectedWallSurface={selectedOption}
            />
          )}
        </div>
        <div
          className={`navigation-buttons ${
            activeTab === "Производитель" || activeTab === "Размеры"
              ? "single-button"
              : ""
          }`}
        >
          {activeTab !== "Производитель" && activeTab !== "Шов" && (
            <div className="button-wrapper">
              <button className="back-button" onClick={handleBack}>
                <img src={arrowL} className="navi-arrow" alt="navi-btn-icon" />
                Назад
              </button>
            </div>
          )}
          {(activeTab === "Производитель" || activeTab === "Размеры") && (
            <div className="button-wrapper">
              <button
                className={`next-button ${
                  (activeTab === "Производитель" && selectedManufacturer) ||
                  activeTab === "Размеры"
                    ? "next-button-active"
                    : ""
                }`}
                onClick={handleNext}
              >
                Далее
                <img src={arrowR} className="navi-arrow" alt="navi-btn-icon" />
              </button>
            </div>
          )}
          {activeTab === "Шов" && (
            <>
              <div className="button-wrapper">
                <button
                  className="back-button"
                  onClick={() => setActiveTab("Размеры")}
                >
                  <img
                    src={arrowL}
                    className="navi-arrow"
                    alt="navi-btn-icon"
                  />
                  Назад
                </button>
              </div>
              <div className="button-wrapper">
                <button
                  className="next-button calc-btn"
                  onClick={handleCalculate}
                >
                  Далее
                  <img
                    src={arrowR}
                    className="navi-arrow"
                    alt="navi-btn-icon"
                  />
                </button>
              </div>
            </>
          )}
        </div>
        <div className="project-container">
          {["Производитель", "Размеры", "Шов"].includes(activeTab) && (
            <div className="project-card">
              <div className="project-card-content">
                <h2>План проекта гипсокартонного потолка за 2 минуты</h2>
                <p>
                  Опробуйте алгоритм самостоятельно, на данный момент создание
                  проектов производится абсолютно бесплатно.
                </p>
                <button
                  className="project-try-button"
                  onClick={() =>
                    window.open("https://plan.itstroyka.com/editor", "_blank")
                  }
                >
                  Попробовать
                </button>
              </div>
              <div className="project-card-image">
                <img
                  src={require("../img/computer.png")}
                  alt="Project Preview"
                />
              </div>
            </div>
          )}
        </div>
      </main>
    </div>
  );
}

export default NewCalculation;
