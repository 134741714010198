import React from "react";
import { motion } from "framer-motion";
import "../style/App.css";

function InfoPanel({ isOpen, onClose }) {
  return (
    <>
      {isOpen && (
        <div className="info-panel-container">
          <motion.div
            className="info-panel-content"
            initial={{ opacity: 0, x: "100%" }}
            animate={{ opacity: 1, x: "0%" }}
            exit={{ opacity: 0, x: "100%" }}
            transition={{ duration: 0.3 }}
          >
            <div className="info-panel-header">
              <h4>Что такое Q1-Q4?</h4>
              <button className="close-button" onClick={onClose}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.833 5.833L14.166 14.167M5.833 14.167L14.166 5.833"
                    stroke="#0D0D0D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
            <div className="info-panel-body">
              <ul>
                <li>
                  <strong>
                    Уровень Q1 соответствует поверхностям без декоративных
                    требований
                  </strong>{" "}
                  Отделка гипсокартона уровня Q1 является достаточной, если к
                  поверхности не предъявляются декоративные требования.
                  Поверхности уровня качества Q1 могут быть покрыты плиткой,
                  листовым материалом или толстослойной штукатуркой.
                </li>
                <li>
                  <strong>
                    Уровень Q2 соответствует поверхностям с минимальной степенью
                    гладкости
                  </strong>{" "}
                  Поверхность гипсокартона со степенью отделки Q2 подходит для
                  структурированных обоев, структурированной краски и покрытий,
                  наносимых вручную с помощью валиков из овчины или
                  структурированных валиков, а также толстых финишных покрытий с
                  размером частиц более 1 мм.
                </li>
                <li>
                  <strong>
                    Уровень Q3 соответствует поверхностям с повышенными
                    требованиями к визуальному качеству
                  </strong>{" "}
                  Поверхность гипсокартона со степенью отделки Q3 подходит для
                  тонких структурированных обоев и лакокрасочных покрытий, а
                  также финишных покрытий с размером частиц менее 1 мм.
                </li>
                <li>
                  <strong>
                    Уровень Q4 соответствует поверхностям, подготовленным
                    согласно самым высоким декоративным стандартам
                  </strong>{" "}
                  Отделка уровня Q4 соответствует самым высоким требованиям к
                  визуальному качеству поверхности. Отделка такого качества
                  позволяет декорировать поверхность глянцевыми обоями, красками
                  или лаками, а также мраморной штукатуркой и другими
                  аналогичными отделочными материалами.
                </li>
              </ul>
            </div>
          </motion.div>
        </div>
      )}
    </>
  );
}

export default InfoPanel;
