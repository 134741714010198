import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
  useId,
} from "react";
import {default as UUID} from "node-uuid";
const MaterialsContext = createContext();

export const MaterialsProvider = ({ children }) => {
  const [wallArea, setWallArea] = useState(0);
  const [selectedWallSurface, setSelectedWallSurface] = useState("");
  const [selectedManufacturer, setSelectedManufacturer] = useState("");
  const [selectedSeamType, setSelectedSeamType] = useState("");

  const [baseMaterials, setBaseMaterials] = useState([]); // Массив всех материалов
  const [resultMaterials, setResultMaterials] = useState([]); // Массив для рендера итогового результата
  const [configurationMaterials, setConfigurationMaterials] = useState([]); // Массив для конфигуратора
  const [calculatedMaterials, setCalculatedMaterials] = useState([]); // Массив с рассчитанными данными
  const [groupedMaterials, setGroupedMaterials] = useState([]);

  const [baseJobs, setBaseJobs] = useState([]);
  const [resultJobs, setResultJobs] = useState([]);
  const [configurationJobs, setConfigurationJobs] = useState([]);
  const [calculatedJobs, setCalculatedJobs] = useState([]);

  const setJobs = (jobs) => {
    setBaseJobs(jobs);
    setConfigurationJobs(jobs);
  };

  const addJobToResult = (jobId) => {
    const job = baseJobs.find((x) => x._id === jobId);
    if (job && !resultJobs.some((item) => item._id === jobId)) {
      setResultJobs((prev) => [...prev, { ...job }]);
      setConfigurationJobs((prev) => prev.filter((m) => m._id !== jobId));
    }
  };

  const calculateJobs = useCallback((jobs, area) => {
    return jobs.map((job) => ({
      id: job._id,
      name: job.name,
      consumptionPerSquareMeter: job.price,
      totalPrice: job.price * area,
    }));
  }, []);

  useEffect(() => {
    setCalculatedJobs(calculateJobs(resultJobs, wallArea));
  }, [resultJobs, wallArea, calculateJobs]);

  const removeJobFromResult = (jobId) => {
    const job = resultJobs.find((m) => m._id === jobId);
    if (job) {
      setResultJobs((prev) => prev.filter((m) => !(m._id === jobId)));
      setConfigurationJobs((prev) => [...prev, job]);
    }
  };

  const setMaterials = (materials, seamType, manufacturer, wallSurface) => {
    setSelectedSeamType(seamType);
    setSelectedManufacturer(manufacturer);
    setSelectedWallSurface(wallSurface);
    let _seamType = seamType;
    if (manufacturer === "Knauf") {
      _seamType = "none"
    }
    
    // Apply filtering by wall surface and seam type for each material
    let filteredMaterials = materials
      .map((material) => ({
        ...material,
        stages: material.stages.filter(
          (stage) =>
            stage.wallType === wallSurface &&
            stage.jointType === _seamType
        ),
      }))
      .filter((material) => material.stages.length); // Remove materials without suitable stages
  
    // Set baseMaterials to the initial filteredMaterials
    setBaseMaterials(filteredMaterials);
  
    // Remove materials that are mutual analogs, keeping only one
    const materialMap = new Map();
  
    // Build a map of materials for quick lookup
    filteredMaterials.forEach((material) => {
      materialMap.set(material._id, material);
    });
  
    const materialsToRemove = new Set();
  
    // For each material, check if any of its analogs reference back to it
    filteredMaterials.forEach((material) => {
      material.stages.forEach((stage) => {
        stage.analogs.forEach((analog) => {
          // Check if the analog is in the filtered materials
          if (materialMap.has(analog._id)) {
            const analogMaterial = materialMap.get(analog._id);
  
            // Check if the analog's stages' analogs include the current material
            analogMaterial.stages.forEach((analogStage) => {
              analogStage.analogs.forEach((analogOfAnalog) => {
                if (analogOfAnalog._id === material._id) {
                  // Mutual analog found
                  // Decide which one to keep; for example, keep the one with the lower _id
                  if (material._id > analogMaterial._id) {
                    materialsToRemove.add(material._id);
                  } else {
                    materialsToRemove.add(analogMaterial._id);
                  }
                }
              });
            });
          }
        });
      });
    });
  
    const resultMaterials = filteredMaterials.filter(
      (material) => !materialsToRemove.has(material._id)
    );
  
    console.log("Filtered Materials after removing mutual analogs:", resultMaterials);
  
    setResultMaterials(resultMaterials);
    setConfigurationMaterials([]);
  };  
  
  const addMaterialToResult = (materialId, stage) => {
    const material = baseMaterials.find((m) => m._id === materialId);
    if (material) {
      const stageData = material.stages.find((s) => s.stage === stage);
      if (stageData) {
        setResultMaterials((prev) => [
          ...prev,
          {
            ...material,
            stages: [stageData], // Добавляем только выбранную стадию
            stage: stageData.stage, // Отдельно указываем текущую стадию для удобства
          },
        ]);
  
        // Удаляем из конфигуратора только выбранный материал и стадию
        setConfigurationMaterials((prev) =>
          prev.map((m) =>
            m._id === materialId
              ? { ...m, stages: m.stages.filter((s) => s.stage !== stage) }
              : m
          ).filter((m) => m.stages.length > 0) // Удаляем материал, если у него больше нет стадий
        );
      }
    }
  };  

  const removeMaterialFromResult = (stage, materialId) => {
    setResultMaterials((prev) => {
      return prev
        .map((m) => {
          if (m._id !== materialId) return m; // Оставляем материал, если не требует изменения
  
          // Фильтруем стадии и проверяем, остались ли они
          const updatedStages = m.stages.filter((s) => s.stage !== stage);
          if (updatedStages.length) {
            // Если остались стадии, возвращаем материал с обновленными стадиями
            return { ...m, stages: updatedStages };
          } else {
            // Если стадий не осталось, удаляем материал, возвращая `null`
            return null;
          }
        })
        .filter(Boolean); // Удаляем `null` из массива, если стадий не осталось
    });
  
    // Возвращаем удаленную стадию материала в configurationMaterials
    setConfigurationMaterials((prev) => {
      const existingMaterialIndex = prev.findIndex((m) => m._id === materialId);
  
      if (existingMaterialIndex !== -1) {
        // Материал уже существует в конфигураторе - добавляем стадию, если её нет
        const material = prev[existingMaterialIndex];
        const stageExists = material.stages.some((s) => s.stage === stage);
  
        if (!stageExists) {
          const stageToAdd = resultMaterials
            .find((m) => m._id === materialId)
            ?.stages.find((s) => s.stage === stage);
  
          if (stageToAdd) {
            const updatedMaterial = {
              ...material,
              stages: [...material.stages, stageToAdd], // Добавляем стадию обратно
            };
            return prev.map((m, idx) =>
              idx === existingMaterialIndex ? updatedMaterial : m
            );
          }
        }
        return prev;
      } else {
        // Если материала нет, добавляем его как новый объект с выбранной стадией
        const originalMaterial = baseMaterials.find((m) => m._id === materialId);
        if (originalMaterial) {
          return [
            ...prev,
            {
              ...originalMaterial,
              stages: originalMaterial.stages.filter((s) => s.stage === stage),
            },
          ];
        }
        return prev;
      }
    });
  };
  
  const changeOnAnalog = (stage, materialId, analogId) => {
    const updatedMaterials = resultMaterials.map((m) => {
      if (m._id !== materialId) return m; // Если это не целевой материал, возвращаем его без изменений
  
      // Находим аналог в базе материалов
      const analogMaterial = baseMaterials.find((mat) => mat._id === analogId);
      if (!analogMaterial) {
        console.error(`Аналог с _id ${analogId} не найден`);
        return m;
      }
  
      console.log("Заменяем материал:", m);
      console.log("На аналог:", analogMaterial);
  
      // Возвращаем аналоговый материал с его полным набором стадий
      return {
        ...analogMaterial, // Основные данные из аналогового материала
        stages: analogMaterial.stages.map((s) => ({ ...s })), // Копируем все стадии из аналога
      };
    });
  
    setResultMaterials(updatedMaterials); // Обновляем состояние
  };

  const changeJobPrice = (localId, price) => {
    setCalculatedMaterials((prevMaterials) =>
      prevMaterials.map((material) =>
        material.localId === localId
          ? { ...material, jobPrice: price } 
          : material 
      )
    );
  };  
  
  const calculateMaterials = useCallback(() => {
    if (!wallArea || !resultMaterials.length) return [];

    const results = resultMaterials.flatMap((material) =>
      material.stages.map((stage) => {
        let requiredAmount;
        if (stage.formula) {
          try {
            const formulaBody = stage.formula.split("=")[1].trim();
            const formulaWithArea = formulaBody.replace(/m2/g, wallArea);
            requiredAmount = new Function(`return ${formulaWithArea}`)();
          } catch (error) {
            console.error(
              `Ошибка при вычислении формулы для материала ${material.id}:`,
              error
            );
            requiredAmount = wallArea * stage.consumptionPerSquareMeter; // fallback
          }
        } else {
          requiredAmount = wallArea * stage.consumptionPerSquareMeter;
        }

        const packagingValue = parseFloat(material.packaging);
        const totalPrice = material.price * (packagingValue > 0
        ? (requiredAmount / packagingValue).toFixed(1) 
        : "0");

        const localId = UUID.v4(); // id для локального использования

        return {
          id: material._id,
          localId: localId,
          itemId: material.id,
          name: `${stage.stage} - ${material.name}`, 
          order: stage.order,
          quantity: requiredAmount.toFixed(1),
          units:
            packagingValue > 0
              ? (requiredAmount / packagingValue).toFixed(1) 
              : "0",
          icon: material.icon,
          unit: material.unit,
          packaging: material.packaging,
          analogs: stage.analogs,
          consumptionPerSquareMeter: stage.consumptionPerSquareMeter,
          price: material.price,
          stage: stage.stage,
          totalPrice: totalPrice,
          jobPrice: 0,
        };
      })
    );
  
    const resultList = results.sort((a, b) => {
      if (a.order != null && b.order != null) {
        return a.order - b.order; // Сортировка по order, если оба значения присутствуют
      } else {
        return a.stage.localeCompare(b.stage); // Сортировка по stage, если order отсутствует
      }
    });
    

    return resultList;
  }, [wallArea, resultMaterials]);

  useEffect(() => {
    setCalculatedMaterials(calculateMaterials());
  }, [wallArea, baseMaterials, selectedSeamType, selectedWallSurface, selectedManufacturer, calculateMaterials]);

  useEffect(() => {
    if (!resultMaterials.length) return;
  
    const groupedResults = resultMaterials.flatMap((material) =>
      material.stages.map((stage) => {
        let requiredAmount;
        if (stage.formula) {
          try {
            const formulaBody = stage.formula.split("=")[1].trim();
            const formulaWithArea = formulaBody.replace(/m2/g, wallArea);
            requiredAmount = new Function(`return ${formulaWithArea}`)();
          } catch (error) {
            console.error(
              `Ошибка при вычислении формулы для материала ${material.id}:`,
              error
            );
            requiredAmount = wallArea * stage.consumptionPerSquareMeter; // fallback
          }
        } else {
          requiredAmount = wallArea * stage.consumptionPerSquareMeter;
        }
  
        const packagingValue = parseFloat(material.packaging);
        const totalPrice = material.price * (packagingValue > 0 ? requiredAmount / packagingValue : 0);
  
        // Находим соответствующий элемент в calculatedMaterials с учетом id и stage
        const calculatedMaterial = calculatedMaterials.find(
          (calc) => calc.id === material.id && calc.stage === stage.stage
        );
        const jobPrice = calculatedMaterial ? calculatedMaterial.jobPrice : 0;
  
        return {
          id: material.id,
          name: material.name,
          icon: material.icon,
          quantity: requiredAmount.toFixed(2),
          units: packagingValue > 0 ? (requiredAmount / packagingValue).toFixed(2) : 0,
          price: material.price,
          totalPrice: totalPrice.toFixed(2),
          jobPrice: jobPrice,
        };
      })
    );

    const groupedById = groupedResults.reduce((acc, item) => {
      const existing = acc.find((m) => m.id === item.id);
      const itemQuantity = parseFloat(item.quantity) || 0;
      const itemUnits = parseFloat(item.units) || 0;
      const itemTotalPrice = parseFloat(item.totalPrice) || 0;
      const itemJobPrice = parseFloat(item.jobPrice) || 0;
  
      if (existing) {
        existing.quantity = (parseFloat(existing.quantity) + itemQuantity).toFixed(2);
        existing.units = (parseFloat(existing.units) + itemUnits).toFixed(2);
        existing.totalPrice = (parseFloat(existing.totalPrice) + itemTotalPrice).toFixed(2);
        existing.jobPrice = (parseFloat(existing.jobPrice) + itemJobPrice).toFixed(2);
      } else {
        acc.push({
          ...item,
          quantity: itemQuantity.toFixed(2),
          units: itemUnits.toFixed(2),
          totalPrice: itemTotalPrice.toFixed(2),
          jobPrice: itemJobPrice.toFixed(2),
        });
      }
      return acc;
    }, []);
  
    console.log(groupedById); // проверка результатов
    setGroupedMaterials(groupedById);
  }, [resultMaterials, calculatedMaterials, wallArea]);  

  return (
    <MaterialsContext.Provider
      value={{
        baseMaterials,
        resultMaterials,
        configurationMaterials,
        groupedMaterials,
        configurationJobs,
        resultJobs,
        calculatedJobs,
        calculatedMaterials,
        wallArea,
        changeJobPrice,
        setMaterials,
        setJobs,
        addJobToResult,
        removeJobFromResult,
        addMaterialToResult,
        removeMaterialFromResult,
        changeOnAnalog,
        calculateMaterials,
        setWallArea,
        setSelectedWallSurface,
        setSelectedManufacturer,
        setSelectedSeamType,
        calculateJobs,
      }}
    >
      {children}
    </MaterialsContext.Provider>
  );
};

export const useMaterials = () => useContext(MaterialsContext);
