import React from 'react';
import lockIcon from '../img/in-progress.svg';

const SelectableCard = ({ image, altText, isSelected, isLocked, onSelect, backgroundColor }) => {
  return (
    <div
      className={`selectable-card ${isSelected ? 'selected' : ''} ${isLocked ? 'locked' : ''}`}
      onClick={!isLocked ? onSelect : null}
      style={{ backgroundColor }}
    >
      <img src={image} alt={altText} className="manufacturer-logo" />
      {isLocked && (
        <>
          <div className="lock-overlay">
            <img src={lockIcon} alt="Lock" className=" lock-icon manufacturer-logo" />
          </div>
          <div className="lock-text">В работе</div>
        </>
      )}
    </div>
  );
};

export default SelectableCard;
