import React, { useState } from "react";
import Sidebar from "../components/SideBar";
import "../style/App.css";
import CancelSubscriptionModal from "../components/UnsubscribeModal";

function SubscriptionPage() {
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSubscribe = () => {
    setIsSubscribed(true);
  };

  const handleCancelSubscription = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="container">
      <Sidebar />

      <div className="subscription-content">
        <h1 className="subscription-h1">Цены</h1>

        {!isSubscribed ? (
          <div className="subscription-card-wrapper">
            <div className="subscription-card">
              <div className="subscription-header ">
                <p className="color-highlight">10 расчетов</p>
                {/* <p>Безлимитное количество расчетов</p> */}
              </div>
              <div className="subscription-price">
                <span className="price color-highlight">500 ₽ / 10 расчетов</span>
              </div>
              <div className="sub-border-bot"></div>
              <div className="subscription-total">
                <p>Всего</p>
                <div className="subscription-total-month">
                  <span className="total">Итого за 10 расчетов:</span>
                  <span> 500 ₽</span>
                </div>
              </div>
            </div>

            <div className="subscribe-button-container">
              <button className="subscribe-button" onClick={handleSubscribe}>
                Купить
              </button>
            </div>
          </div>
        ) : (
          <div className="subscription-card-wrapper-payd">
            <div className="subscription-card">
              <div className="unsubscription-header">
                <p className="color-highlight">Куплено 10 расчетов</p>
              </div>
              <div className="tarif-wrapper">
                <span className="tarif">Активно: 10 расчетов</span>
                <div className="price">
                  <span className="total tarif">Стоимость:</span>
                  <span className="tarif"> 500 ₽/мес</span>
                </div>
              </div>
              {/* <strong>
                <span className="total">Дата следующего платежа: </span>
                <span>08.11.2024</span>
              </strong> */}
            </div>

            {/* <div className="cancel-button-container">
              <button
                className="cancel-button"
                onClick={handleCancelSubscription}
              >
                Отменить подписку
              </button>
            </div> */}
          </div>
        )}
      </div>

      <CancelSubscriptionModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
      />
    </div>
  );
}

export default SubscriptionPage;
