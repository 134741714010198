import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../img/Logo-circle.svg";
import menuIcon from "../img/menu.svg";
import closeIcon from "../img/close-landing.svg";
import loginIcon from "../img/landing-login.svg";
import "../style/Landing.css";

const HeaderLanding = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const updateMobileStatus = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMobileStatus);
    return () => window.removeEventListener("resize", updateMobileStatus);
  }, []);

    // Проверка, если текущий путь равен '/pricing'
    const isPricingPage = location.pathname === "/pricing";

  return (
    <header className="landing-header">
      <div className="header-left-wrapper">
        <div className="header-left" onClick={() => navigate("/")}>
          {isMobile && (
            <img
              src={isMenuOpen ? closeIcon : menuIcon}
              className="landing-menu-icon"
              alt={isMenuOpen ? "Close" : "Menu"}
              onClick={toggleMenu}
            />
          )}
          <img
            src={logo}
            className="header-logo"
            alt="logo"
            style={{ cursor: "pointer" }}
          />
          {!isMobile && (
            <div className="header-text-landing">
              <h1>Территория маляров</h1>
              <p>От мастера мастеру</p>
            </div>
          )}
        </div>
        {!isMobile && (
          <div className="header-center">
          <button
            onClick={() => navigate("/pricing")}
            className={`header-link ${isPricingPage ? "active-link" : ""}`}
            style={{ color: isPricingPage ? "#006C4F" : "inherit" }}
          >
            Цены
          </button>
          </div>
        )}
      </div>
      <div className="header-right">
        {isMobile ? (
          <img
            src={loginIcon}
            className="login-icon"
            alt="Login"
            onClick={() => navigate("/login")}
          />
        ) : (
          <>
            <button onClick={() => navigate("/register")} className="landing-register-button">
              Регистрация
            </button>
            <button onClick={() => navigate("/login")} className="landing-login-button">
              Вход
            </button>
          </>
        )}
      </div>

      {/* Мобильное меню */}
      {isMobile && isMenuOpen && (
        <div className="mobile-menu">
          <button onClick={() => navigate("/pricing")} className="mobile-menu-link">
            Цены
          </button>
        </div>
      )}
    </header>
  );
};

export default HeaderLanding;
