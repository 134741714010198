import React from "react";
import "../style/Landing.css";
import { useNavigate } from "react-router-dom";

const FooterLanding = ({ onLinkClick }) => {
  const navigate = useNavigate();

  return (
    <footer className="landing-footer">
      <div className="landing-footer-container">
        <a href="#" className="footer-link" onClick={() => navigate("/")}>
          Контакты
        </a>
        <a href="#" className="footer-link">
          Все права защищены ©2024
        </a>
        <a onClick={() => navigate("/terms")} className="footer-link">
          Пользовательское соглашение
        </a>
        <a onClick={() => navigate("/privacy")} className="footer-link">
          Политика конфиденциальности
        </a>
      </div>
    </footer>
  );
};

export default FooterLanding;
