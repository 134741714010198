import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

function PublicRoute({ children, restricted }) {
  const { isAuthenticated } = useAuth();

  return isAuthenticated && restricted ? (
    <Navigate to="/new-calculation" />
  ) : (
    children
  );
}

export default PublicRoute;
