import React from "react";
import trashIcon from "../img/trash.svg";
import { useMaterials } from "../contexts/MaterialsContext";

function WorkItem({ name, consumption, totalPrice, id }) {
  const { removeJobFromResult } = useMaterials();

  return (
    <div className="work-item">
      <div className="work-name-wrapper">
        <label className="work-label">Тип работы</label>
        <span className="work-item-name">{name}</span>
      </div>
      <div className="work-right">
        <div className="work-wrapper">
          <label className="work-label">Цена за м2</label>
          <span className="work-consumption">{consumption.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} Р/м²</span>
        </div>
        <div className="work-wrapper">
          <label className="work-label">Всего</label>
          <span className="work-total">{totalPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ₽</span>
        </div>
        <div className="work-price-delete">
          <button
            className="work-delete-button"
            onClick={() => removeJobFromResult(id)}
          >
            <img src={trashIcon} alt="Удалить" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default WorkItem;
