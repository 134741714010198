import React, { useEffect, useState } from 'react';
import { getUrl } from "../components/apiUrl";

function MaterialsPage() {
  const [materials, setMaterials] = useState([]);
  const [analogs, setAnalogs] = useState('');
  const [error, setError] = useState('');
  const apiUrl = getUrl();

  useEffect(() => {
    fetchMaterials();
  }, []);

  const fetchMaterials = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/materials`);
      if (!response.ok) throw new Error('Ошибка при загрузке материалов');
      const data = await response.json();
      setMaterials(data);
    } catch (err) {
      console.error(err);
      setError('Не удалось загрузить материалы');
    }
  };

  const addAnalog = async (materialId) => {
    try {
      const response = await fetch(`${apiUrl}/api/materials/add-analogs/${materialId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ analogs }),
      });
      if (!response.ok) throw new Error('Ошибка при добавлении аналога');
      fetchMaterials();
      setAnalogs('');
    } catch (err) {
      console.error(err);
      setError('Не удалось добавить аналог');
    }
  };

  const removeAnalog = async (materialId) => {
    try {
      const response = await fetch(`${apiUrl}/api/materials/remove-analogs/${materialId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ analogs }),
      });
      if (!response.ok) throw new Error('Ошибка при удалении аналога');
      fetchMaterials();
      setAnalogs('');
    } catch (err) {
      console.error(err);
      setError('Не удалось удалить аналог');
    }
  };

  return (
    <div style={{ padding: '20px', height: '100vh', overflow: 'hidden' }}>
      <h2>Список материалов</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      
      <div style={{ maxHeight: '100vh', overflowY: 'auto', paddingRight: '10px' }}>
        <ul>
          {materials.map((material) => (
            <li key={material.id} style={{ marginBottom: '15px' }}>
              <h4>{material.name}</h4>
              <input
                type="text"
                value={analogs}
                onChange={(e) => setAnalogs(e.target.value)}
                placeholder="Введите ID аналога"
                style={{ marginRight: '10px' }}
              />
              <button onClick={() => addAnalog(material.id)}>Добавить аналог</button>
              <button onClick={() => removeAnalog(material.id)} style={{ marginLeft: '10px' }}>
                Удалить аналог
              </button>
              <ul>
                {material.analogs && material.analogs.length > 0 ? (
                  material.analogs.map((analog) => (
                    <li key={analog} style={{ fontSize: '14px', color: 'gray' }}>
                      Аналог ID: {analog}
                    </li>
                  ))
                ) : (
                  <li style={{ fontSize: '14px', color: 'gray' }}>Нет аналогов</li>
                )}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default MaterialsPage;
