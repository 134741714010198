import React from "react";
import closeIcon from "../img/Close_MD.svg";
import exampleImage from "../img/sub-bcc2.png";

const UpgradeModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="upgrade-modal-overlay" onClick={onClose}>
      <div
        className="upgrade-modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="upgrade-modal-header">
          <h2 className="upgrade-modal-title">
            Больше возможностей с подпиской
          </h2>
          <button className="upgrade-modal-close-btn" onClick={onClose}>
            <img src={closeIcon} alt="Закрыть" />
          </button>
        </div>
        <div className="upgrade-modal-body">
          <img
            src={exampleImage}
            alt="Подписка"
            className="upgrade-modal-image"
          />
          <h3 className="upgrade-modal-subtitle">
            Бесконечное количество расчетов
          </h3>
          <p className="upgrade-modal-text">
            Вы уже попробовали облегчить себе работу с помощью калькулятора
            “Территория Маляров”, пришло время приобрести подписку, чтобы ваш
            помощник был всегда под рукой.
          </p>
          <button className="upgrade-modal-button">Купить подписку</button>
        </div>
      </div>
    </div>
  );
};

export default UpgradeModal;
