import React, { useState, useMemo, useEffect } from "react";
import "../../style/App.css";
import Sidebar from "../../components/SideBar";
import LoadingScreen from "../../components/LoadingScreen";
import { getUrl } from "../../components/apiUrl";
import search from "../../img/saerch.svg";
import pen from "../../img/pen.svg";

function MyPrices() {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedManufacturer, setSelectedManufacturer] = useState("");
  const [editingItemId, setEditingItemId] = useState(null);
  const [editingJobItemId, setJobEditingItemId] = useState(null);

  const [editingJobPrice, setEditingJobPrice] = useState("");
  const [editingPrice, setEditingPrice] = useState("");

  const [showOnlyUnfilledPrices, setShowOnlyUnfilledPrices] = useState(false);
  const [activeTab, setActiveTab] = useState("materials");
  const [materialList, setMaterialList] = useState([]);
  const [jobList, setJobList] = useState([]);
  const manufacturers = [
    { name: "Knauf", label: "Knauf", isLocked: false },
    { name: "Smit", label: "СМиТ", isLocked: false },
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const fetchMaterialsAndPrices = async () => {
      try {
        const apiUrl = getUrl();

        const [jobsResponse, pricesResponse] = await Promise.all([
          fetch(`${apiUrl}/api/jobs`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }),
          fetch(`${apiUrl}/api/job-price`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }),
        ]);

        if (!jobsResponse.ok || !pricesResponse.ok) {
          throw new Error("Failed to fetch jobs or prices");
        }

        const jobs = await jobsResponse.json();
        const prices = await pricesResponse.json();

        const mergedData = jobs.map((job) => ({
          ...job,
          price: prices.find((price) => price.job._id === job._id)?.price || 0,
        }));

        setJobList(mergedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchMaterialsAndPrices();
  }, []);

  useEffect(() => {
    const fetchMaterialsAndPrices = async () => {
      setIsLoading(true);
      try {
        const apiUrl = getUrl();
        const manufacture = selectedManufacturer.toLowerCase();

        const [materialsResponse, pricesResponse] = await Promise.all([
          fetch(
            `${apiUrl}/api/materials/get-materials-by-manufacture/${manufacture}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          ),
          fetch(`${apiUrl}/api/material-price`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }),
        ]);

        if (!materialsResponse.ok || !pricesResponse.ok) {
          throw new Error("Failed to fetch materials or prices");
        }

        const materials = await materialsResponse.json();
        const prices = await pricesResponse.json();

        const mergedData = materials.map((material) => ({
          ...material,
          price:
            prices.find((price) => price?.material?._id === material?._id)
              ?.price || 0,
        }));

        setMaterialList(mergedData);
        console.log("MERGED", mergedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (selectedManufacturer) {
      fetchMaterialsAndPrices();
    }
  }, [selectedManufacturer]);

  const filterAndSortItems = (items) => {
    return items.filter((item) => {
      // Проверка совпадения с поисковым запросом
      const matchesSearch = item.name
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      // Проверка наличия цены: исключаем элементы с null, undefined или 0 как "без цены"
      const hasPrice = item.price != null && item.price !== 0;

      // Если фильтр "Показать позиции без цен" включен, возвращаем только элементы без цены
      if (showOnlyUnfilledPrices) {
        return matchesSearch && !hasPrice;
      }

      // В противном случае, возвращаем все совпадающие элементы
      return matchesSearch;
    });
  };

  const filteredItems = useMemo(
    () => filterAndSortItems(materialList),
    [materialList, searchTerm, showOnlyUnfilledPrices]
  );

  const handlePriceChange = (event) => {
    const value = parseFloat(event.target.value);
    if (value >= 0 || event.target.value === "") {
      setEditingPrice(event.target.value);
    }
  };

  const handleJobPriceChange = (event) => {
    const value = parseFloat(event.target.value);
    if (value >= 0 || event.target.value === "") {
      setEditingJobPrice(event.target.value);
    }
  };

  const handleJobPriceConfirm = () => {
    if (editingJobItemId) {
      const payload = {
        job: editingJobItemId,
        price: parseFloat(editingJobPrice),
      };

      fetch(`${getUrl()}/api/job-price/update-price/${editingJobItemId}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(payload),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Ошибка HTTP: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setJobList((prevList) =>
            prevList.map((job) =>
              job._id === editingJobItemId
                ? { ...job, price: parseFloat(editingJobPrice) }
                : job
            )
          );
          setJobEditingItemId(null);
          setEditingJobPrice("");
        })
        .catch((error) => {
          console.error("Error updating price:", error);
        });
    }
  };

  const handleMaterialPriceConfirm = () => {
    if (editingItemId) {
      const payload = {
        material: editingItemId,
        price: parseFloat(editingPrice),
      };

      fetch(`${getUrl()}/api/material-price/update-price/${editingItemId}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(payload),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Ошибка HTTP: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setMaterialList((prevList) =>
            prevList.map((item) =>
              item._id === editingItemId
                ? { ...item, price: parseFloat(editingPrice) }
                : item
            )
          );
          setEditingItemId(null);
          setEditingPrice("");
        })
        .catch((error) => {
          console.error("Error updating price:", error);
        });
    }
  };

  const handleJobEditClick = (id) => {
    setJobEditingItemId(id);
  };

  const handleEditClick = (id) => {
    setEditingItemId(id);
  };

  const handleCancel = () => {
    setEditingItemId(null);
    setEditingPrice("");
  };

  const handleJobCancel = () => {
    setJobEditingItemId(null);
    setEditingJobPrice("");
  };

  const handleToggleChange = () => {
    setShowOnlyUnfilledPrices((prev) => !prev);
  };

  return (
    <div className="container">
      <Sidebar />
      <div className="main-content my-prices-page">
        <header className="main-header">
          <h1>Мои цены</h1>
          <p>Заполните информацию о ценах для расчета сметы материалов</p>
        </header>

        <div className="tab-container">
          <button
            className={`tab-button ${
              activeTab === "materials" ? "active" : ""
            }`}
            onClick={() => setActiveTab("materials")}
          >
            Материалы
          </button>
          <button
            className={`tab-button ${activeTab === "works" ? "active" : ""}`}
            onClick={() => setActiveTab("works")}
          >
            Работы
          </button>
        </div>

        {activeTab === "materials" ? (
          <div className="my-price-results results-container custom-scrollbar">
            <div className="filters">
              <div className="search-container">
                <input
                  type="text"
                  className="search-input"
                  placeholder="Поиск"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  disabled={!selectedManufacturer}
                />
                <button className="search-button">
                  <img className="search-icon" src={search} alt="Поиск" />
                </button>
              </div>

              <select
                className="manufacturer-select"
                value={selectedManufacturer}
                onChange={(e) => setSelectedManufacturer(e.target.value)}
              >
                <option value="">Выбрать производителя</option>
                {manufacturers.map((manufacturer) => (
                  <option
                    key={manufacturer.name}
                    value={manufacturer.name}
                    disabled={manufacturer.isLocked}
                  >
                    {manufacturer.label}
                  </option>
                ))}
              </select>
            </div>
            {isLoading && (
              <LoadingScreen progress={progress} setProgress={setProgress} />
            )}

            {selectedManufacturer && (
              <div className="show-unfilled-toggle">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={showOnlyUnfilledPrices}
                    onChange={handleToggleChange}
                  />
                  <span className="slider round"></span>
                </label>
                <span className="toggle-label-text">
                  Показать позиции без цен
                </span>
              </div>
            )}

            {selectedManufacturer ? (
              <div className="my-price-results results-container custom-scrollbar">
                {filteredItems.length > 0 ? (
                  filteredItems.map((item) => (
                    <div key={item._id} className="supplies-item">
                      <div className="supplies-header">
                        <img src={item.icon} alt="" className="supplies-icon" />
                        <div className="supplies-detail my-prices">
                          <h3 className="detail-item-name">{item.name}</h3>
                          <p className="detail-value-light">
                            {item.packaging || ""} {item.unit}
                          </p>
                        </div>
                      </div>
                      {editingItemId === item._id ? (
                        <div className="price-input-container">
                          <input
                            type="number"
                            className="price-input"
                            placeholder="Введите цену"
                            value={editingPrice || ""}
                            min={0}
                            onChange={handlePriceChange}
                          />
                          <button
                            className="my-prices-icon-button my-prices-confirm-button"
                            onClick={handleMaterialPriceConfirm}
                          >
                            <svg
                              width="24"
                              height="24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10.6531 17C10.3929 17 10.1458 16.8884 9.96373 16.6931L6.28289 12.7447C5.9057 12.3401 5.9057 11.6704 6.28289 11.2658C6.66008 10.8612 7.28439 10.8612 7.66158 11.2658L10.6531 14.4747L17.3384 7.30345C17.7156 6.89885 18.3399 6.89885 18.7171 7.30345C19.0943 7.70806 19.0943 8.37775 18.7171 8.78235L11.3424 16.6931C11.1603 16.8884 10.9132 17 10.6531 17Z"
                                fill="white"
                              />
                            </svg>
                          </button>
                          <button
                            className="my-prices-icon-button my-prices-cancel-button"
                            onClick={handleCancel}
                          >
                            <svg
                              width="24"
                              height="24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.04472 17C7.77918 17 7.51363 16.9022 7.30398 16.6926C6.89867 16.2875 6.89867 15.6168 7.30398 15.2117L15.2145 7.30388C15.6198 6.89871 16.2907 6.89871 16.696 7.30388C17.1013 7.70905 17.1013 8.37967 16.696 8.78484L8.78546 16.6926C8.5898 16.9022 8.31027 17 8.04472 17Z"
                                fill="#CC3333"
                              />
                              <path
                                d="M15.9553 17C15.6897 17 15.4242 16.9022 15.2145 16.6926L7.30398 8.78484C6.89867 8.37967 6.89867 7.70905 7.30398 7.30388C7.70929 6.89871 8.38015 6.89871 8.78546 7.30388L16.696 15.2117C17.1013 15.6168 17.1013 16.2875 16.696 16.6926C16.4864 16.9022 16.2208 17 15.9553 17Z"
                                fill="#CC3333"
                              />
                            </svg>
                          </button>
                        </div>
                      ) : (
                        <div className="price-result-container">
                          <div className="price-result">
                            <span className="price-text">Моя цена</span>
                            <span className="price-number">
                              {item.price !== undefined
                                ? `${item.price} ₽`
                                : "0"}
                            </span>
                          </div>
                          <button
                            className="edit-price"
                            onClick={() => handleEditClick(item._id)}
                          >
                            <img
                              className="edit-icon"
                              src={pen}
                              alt="Рассчитать смету"
                            />
                          </button>
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <p>Нет данных для отображения</p>
                )}
              </div>
            ) : (
              <p className="price-result-p">
                Выберите производителя, чтобы увидеть список позиций
              </p>
            )}
          </div>
        ) : (
          <div className="my-price-results results-container custom-scrollbar">
            <div className="work-list">
              {jobList.map((work) => (
                <div key={work._id} className="work-item">
                  <div className="work-name-wrapper">
                    <label className="work-label">Тип работы</label>
                    <span className="work-item-name">{work.name}</span>
                  </div>

                    {editingJobItemId === work._id ? (
                      <div className="price-input-container">
                        <input
                          type="number"
                          className="price-input"
                          placeholder="Введите цену"
                          value={editingJobPrice || ""}
                          min={0}
                          onChange={handleJobPriceChange}
                        />
                        <button
                          className="my-prices-icon-button my-prices-confirm-button"
                          onClick={handleJobPriceConfirm}
                        >
                          <svg
                            width="24"
                            height="24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.6531 17C10.3929 17 10.1458 16.8884 9.96373 16.6931L6.28289 12.7447C5.9057 12.3401 5.9057 11.6704 6.28289 11.2658C6.66008 10.8612 7.28439 10.8612 7.66158 11.2658L10.6531 14.4747L17.3384 7.30345C17.7156 6.89885 18.3399 6.89885 18.7171 7.30345C19.0943 7.70806 19.0943 8.37775 18.7171 8.78235L11.3424 16.6931C11.1603 16.8884 10.9132 17 10.6531 17Z"
                              fill="white"
                            />
                          </svg>
                        </button>
                        <button
                          className="my-prices-icon-button my-prices-cancel-button"
                          onClick={handleJobCancel}
                        >
                          <svg
                            width="24"
                            height="24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.04472 17C7.77918 17 7.51363 16.9022 7.30398 16.6926C6.89867 16.2875 6.89867 15.6168 7.30398 15.2117L15.2145 7.30388C15.6198 6.89871 16.2907 6.89871 16.696 7.30388C17.1013 7.70905 17.1013 8.37967 16.696 8.78484L8.78546 16.6926C8.5898 16.9022 8.31027 17 8.04472 17Z"
                              fill="#CC3333"
                            />
                            <path
                              d="M15.9553 17C15.6897 17 15.4242 16.9022 15.2145 16.6926L7.30398 8.78484C6.89867 8.37967 6.89867 7.70905 7.30398 7.30388C7.70929 6.89871 8.38015 6.89871 8.78546 7.30388L16.696 15.2117C17.1013 15.6168 17.1013 16.2875 16.696 16.6926C16.4864 16.9022 16.2208 17 15.9553 17Z"
                              fill="#CC3333"
                            />
                          </svg>
                        </button>
                      </div>
                    ) : (
                      <div className="price-result-container">
                        <div className="price-result">
                          <label className="work-label">Оплата за м2</label>
                          <span className="work-consumption">
                            {work.price} Р/м²
                          </span>
                        </div>
                        <div className="price-result">
                          <span className="work-label">Цена</span>
                          <span className="work-consumption">
                            {work.price !== undefined ? `${work.price} ₽` : "0"}
                          </span>
                        </div>
                        <button
                          className="edit-price"
                          onClick={() => handleJobEditClick(work._id)}
                        >
                          <img
                            className="edit-icon"
                            src={pen}
                            alt="Рассчитать смету"
                          />
                        </button>
                      </div>
                    )}

                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default MyPrices;
