import React from "react";
import "../style/Landing.css";
import HeaderLanding from "../components/HeaderLanding";
import FooterLanding from "../components/FooterLanding";
import { useNavigate } from "react-router-dom";

function PricingPage() {
  const navigate = useNavigate();
  return (
    <div className="pricing-page">
      <HeaderLanding />

      <main>
        <section id="pricing" className="landing-pricing-section">
          <div className="small-gap"></div>
          <div className="landing-container">
            <h3>Цена</h3>
            <div className="landing-price-box">
              <div className="price-top">
                <div className="price-header">
                  <span>1 месяц = 10 расчетов</span>
                </div>
                <div className="price-content">
                  <h2>
                    500₽<span>/ мес</span>
                  </h2>
                </div>
              </div>
              <div className="price-bot">
                <div className="price-wrapper">
                  <div className="price-left">
                    <p>Всего</p>
                  </div>
                  <div className="price-right">
                    <p>Всего за месяц</p>
                    <p className="total-price">500 ₽</p>
                  </div>
                </div>
                <button onClick={() => navigate("/register")}  className="landing-subscribe-button">Начать пользоваться</button>
              </div>
            </div>
          </div>
          <div className="small-gap"></div>
        </section>
      </main>

      <FooterLanding />
    </div>
  );
}

export default PricingPage;
