import React, { useState } from "react";
import "../style/App.css";
import refreshIcon from "../img/refresh-2.svg";
import addIcon from "../img/add-circle.svg";
import closeModal from "../img/Close_MD.svg";
import { useMaterials } from "../contexts/MaterialsContext";

function ConfiguratorButton() {
  const {
    configurationMaterials,
    configurationJobs,
    addJobToResult,
    addMaterialToResult,
  } = useMaterials();

  const [isOpen, setIsOpen] = useState(false);

  const togglePanel = () => {
    setIsOpen(!isOpen);
  };

  const closeConfigurator = () => {
    setIsOpen(false);
  };

  const handleAddStageMaterial = (id, stage) => {
    addMaterialToResult(id, stage);
  };

  return (
    <div className={`configurator-container ${isOpen ? "open" : ""}`}>
      <div className="configurator-button" onClick={togglePanel}>
        <span className="arrow-icon">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ transform: isOpen ? "rotate(0deg)" : "rotate(180deg)" }}
          >
            <mask id="path-1-inside-1_93_3074" fill="white">
              <path d="M10.0002 15.1668L6.00016 15.1668C2.38016 15.1668 0.833496 13.6202 0.833496 10.0002L0.833496 6.00016C0.833496 2.38016 2.38016 0.833496 6.00016 0.833496L10.0002 0.833496C13.6202 0.833496 15.1668 2.38016 15.1668 6.00016L15.1668 10.0002C15.1668 13.6202 13.6202 15.1668 10.0002 15.1668ZM6.00016 1.8335C2.92683 1.8335 1.8335 2.92683 1.8335 6.00016L1.8335 10.0002C1.8335 13.0735 2.92683 14.1668 6.00016 14.1668L10.0002 14.1668C13.0735 14.1668 14.1668 13.0735 14.1668 10.0002L14.1668 6.00016C14.1668 2.92683 13.0735 1.8335 10.0002 1.8335L6.00016 1.8335Z" />
            </mask>
            <path
              d="M10.0002 15.1668L6.00016 15.1668C2.38016 15.1668 0.833496 13.6202 0.833496 10.0002L0.833496 6.00016C0.833496 2.38016 2.38016 0.833496 6.00016 0.833496L10.0002 0.833496C13.6202 0.833496 15.1668 2.38016 15.1668 6.00016L15.1668 10.0002C15.1668 13.6202 13.6202 15.1668 10.0002 15.1668ZM6.00016 1.8335C2.92683 1.8335 1.8335 2.92683 1.8335 6.00016L1.8335 10.0002C1.8335 13.0735 2.92683 14.1668 6.00016 14.1668L10.0002 14.1668C13.0735 14.1668 14.1668 13.0735 14.1668 10.0002L14.1668 6.00016C14.1668 2.92683 13.0735 1.8335 10.0002 1.8335L6.00016 1.8335Z"
              fill="#F2F2F2"
            />
            <path
              d="M7.15995 10.8534C7.03329 10.8534 6.90662 10.8068 6.80662 10.7068C6.61329 10.5134 6.61329 10.1934 6.80662 10.0001L8.80662 8.0001L6.80662 6.0001C6.61329 5.80677 6.61329 5.48677 6.80662 5.29344C6.99995 5.1001 7.31995 5.1001 7.51329 5.29344L9.86662 7.64677C10.06 7.8401 10.06 8.1601 9.86662 8.35344L7.51329 10.7068C7.41329 10.8068 7.28662 10.8534 7.15995 10.8534Z"
              fill="#F2F2F2"
            />
          </svg>
        </span>
        <div className="button-label">Конфигуратор</div>
      </div>
      <div className="configurator-panel">
        <div className="configurator-header">
          <h2>Конфигуратор</h2>
          <button className="warning-card-btn" onClick={closeConfigurator}>
            <img className="warning-card-icon" src={closeModal} alt="Закрыть" />
          </button>
        </div>
        <div className="configurator-content">
          <div className="configurator-section">
            <h3>Материалы</h3>
            {configurationMaterials.map((item) =>
              item.stages.map((stage) => (
                <div
                  key={`${item._id}-${stage.stage}`}
                  className="configurator-item"
                >
                  {item.icon && (
                    <img
                      src={item.icon}
                      alt={item.name}
                      className="configurator-item-icon"
                    />
                  )}
                  <div className="configurator-item-info">
                    <span className="configurator-item-name">{item.name}</span>
                    <div className="configurator-stage">
                      <p>Стадия: {stage.stage}</p>
                      <div className="configurator-item-actions">
                        <button
                          className="configurator-action-button"
                          onClick={() =>
                            handleAddStageMaterial(item._id, stage.stage)
                          }
                        >
                          <img
                            src={addIcon}
                            alt="Добавить"
                            className="action-icon"
                          />
                          Добавить
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>

          {/* <div className="configurator-section">
            <h3>Работы</h3>
            {configurationJobs.map((item) => (
              <div key={item._id} className="configurator-item">
                <div className="configurator-item-info">
                  <span className="configurator-item-name">{item.name}</span>
                  <div className="configurator-item-actions">
                    <button
                      className="configurator-action-button"
                      onClick={() => addJobToResult(item._id)}
                    >
                      <img
                        src={addIcon}
                        alt="Добавить"
                        className="action-icon"
                      />
                      Добавить
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default ConfiguratorButton;
