import React, { useState } from "react";
import closeModal from "../img/Close_MD.svg";
import { useAuth } from "../contexts/AuthContext";
import { getUrl } from "./apiUrl";

const FeedbackModal = ({ isOpen, onClose }) => {
  const [feedback, setFeedback] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const { user, token } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!user) {
      setError("Вы должны войти в систему, чтобы отправить обратную связь.");
      return;
    }

    const userId = user.id || user._id;

    const feedbackData = {
      userId,
      feedback: {
        feedback,
        selectedCategory,
      },
    };

    try {
      const apiUrl = getUrl();
      const response = await fetch(`${apiUrl}/api/feedback/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(feedbackData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        setError(errorData.msg || "Ошибка при отправке обратной связи.");
      } else {
        const responseData = await response.json();
        setMessage("Спасибо! Ваше сообщение отправлено.");
        setFeedback("");
        setSelectedCategory("");
        onClose();
      }
    } catch (err) {
      console.error("Ошибка при отправке обратной связи:", err);
      setError("Сетевая ошибка при отправке обратной связи.");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-content-header">
          <h2 className="modal-h2">Новый запрос</h2>
          <button className="warning-card-btn" onClick={onClose}>
            <img className="warning-card-icon" src={closeModal} alt="Закрыть" />
          </button>
        </div>
        <form className="modal-form" onSubmit={handleSubmit}>
          {message && <div className="success-message">{message}</div>}
          {error && <div className="error-message">{error}</div>}

          <select
            className="manufacturer-select modal-dropdown"
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            required
          >
            <option value="" disabled>
              Выберите категорию
            </option>
            <option value="bug">Сообщить об ошибке</option>
            <option value="feature">Предложить функцию</option>
            <option value="other">Другое</option>
          </select>

          <textarea
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            placeholder="Введите текст"
            required
          />
          <button className="modal-btn" type="submit">
            Отправить
          </button>
        </form>
      </div>
    </div>
  );
};

export default FeedbackModal;
